<template>
  <div class="catalog-card-info-list">
    <div class="catalog-card-info-list__inner">
      <h3 v-if="title" class="catalog-card-info-list__title">
        {{ title }}
      </h3>

      <div class="catalog-card-info-list__body">
        <ul
          v-if="items && items.length"
          class="list-unstyled d-flex flex-wrap justify-content-center pl-0"
        >
          <li v-for="(item, index) in items" :key="index" class="mr-1">
            <span>{{ getItemLabelFunc(item) }}</span>
            <span v-if="index < items.length - 1">,</span>
          </li>
        </ul>

        <div
          v-if="description"
          v-html="description"
          class="catalog-card-info-list__description"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CatalogCardInfoList',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: []
    },
    getItemLabelFunc: {
      type: Function,
      default: () => {}
    },
    description: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="sass">
.catalog-card-info-list
  position: relative
  padding-top: 0.5rem

  &__inner
    position: relative
    border-top: 1px solid var(--color-blue-eclipse)
    padding-top: 0.75rem

  &__title
    position: absolute
    left: 0
    top: 0
    transform: translateY(-50%)
    margin-bottom: 0
    padding-right: 0.25rem
    background-color: var(--color-blue-river)
    font-size: 0.875rem
    font-weight: 300
    line-height: 1

  &__description
    min-width: 0
    overflow: hidden
    text-overflow: ellipsis

    &:not(:last-child)
      margin-bottom: 1.5rem

    *
      margin-bottom: 0
</style>
