import { render, staticRenderFns } from "./labelled-count.vue?vue&type=template&id=56b24f69"
import script from "./labelled-count.vue?vue&type=script&lang=js"
export * from "./labelled-count.vue?vue&type=script&lang=js"
import style0 from "./labelled-count.vue?vue&type=style&index=0&id=56b24f69&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports