<template>
  <div :class="$bem()">
    <div class="landing-container">
      <div :class="$bem('inner')" :style="{ minHeight }">
        <div class="landing-header__logo landing-header-logo">
          <div class="landing-header-logo__img">
            <img
              src="@/assets/images/logo/easybase-xs.webp"
              alt="EasyBase"
              width="42"
              height="42"
            />
          </div>

          <span class="landing-header-logo__text">EasyBase</span>
        </div>

        <div>
          <ul :class="$bem('menu')">
            <li>
              <a
                :class="{ active: activeSection === 'home' }"
                @click="scrollTo('home')"
              >
                Головна
              </a>
            </li>

            <li>
              <a
                :class="{ active: activeSection === 'functional' }"
                @click="scrollTo('functional')"
              >
                Функціонал
              </a>
            </li>

            <li>
              <a
                :class="{ active: activeSection === 'about' }"
                @click="scrollTo('about')"
              >
                Про нас
              </a>
            </li>

            <li>
              <a
                :class="{ active: activeSection === 'contacts' }"
                @click="scrollTo('contacts')"
              >
                Контакти
              </a>
            </li>
          </ul>
        </div>

        <button :class="$bem('btn-try')" v-b-modal.try-free-popup>
          Спробувати безкоштовно
        </button>

        <try-free-popup />
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import TryFreePopup from '@/components/popups/try-free-popup';
import { LANDING_HEADER_HEIGHT } from '@/helpers/constants';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'landing-header',
  mixins: [component],
  components: { TryFreePopup },
  data() {
    return {
      minHeight: `${LANDING_HEADER_HEIGHT}px`
    };
  },
  computed: {
    ...mapGetters(['activeSection'])
  },
  methods: {
    ...mapActions(['setActiveSection']),
    scrollTo(id) {
      this.setActiveSection(id);
      if (id === 'home') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        const element = document.getElementById(id);
        const y =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          LANDING_HEADER_HEIGHT;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }
};
</script>
