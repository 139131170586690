<template>
  <div class="item-filter">
    <div class="item-filter__row m-1 d-flex">
      <div
        v-b-toggle="collapse"
        class="d-flex align-items-center justify-content-start w-100"
      >
        <span class="plus-filter" />

        <icon :name="icon" class="filter-item-icon" />

        <span class="header-title">{{ title }}</span>
      </div>

      <slot name="clear"> </slot>
    </div>

    <b-collapse :id="collapse">
      <b-card class="type-filter">
        <slot name="content"></slot>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'FilterItemWrapper',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String
    },
    collapse: {
      type: String
    }
  }
};
</script>

<style lang="sass">
.filter-group-btns
  &__list
    list-style: none
    display: flex
    flex-wrap: wrap
    gap: 0.5rem
    margin: 0
    padding: 0

    &:has(.col-6)
      gap: 0
      margin: 0 -0.5rem

    &--grid
      gap: 0.5rem 0
      margin: 0 -0.25rem

      > li
        flex: 0 0 50%
        max-width: 50%
        padding: 0 0.25rem

        > button
          width: 100%

    > li
      &.col-6
        flex: 0 0 50%
        max-width: 50%
        padding: 0 0.5rem

      > button
        display: block
        border: none
        border-radius: var(--border-radius-24)
        min-height: 1.5rem
        padding: 0.125rem 0.5rem
        background-color: var(--color-white-smoke)
        font-size: 0.75rem
        font-weight: 600
        line-height: 1.2
        color: var(--color-blue-whale)
        vertical-align: top
        overflow: hidden
        white-space: nowrap
        transition: background-color var(--transition-duration-primary), color var(--transition-duration-primary)

        @media (max-width: 992px)
          min-height: 23.5px
          padding: 0.125rem 0.375rem
          font-size: 0.875rem

        &:not(:disabled)
          &:hover
            background-color: var(--color-havasupai-falls)
            color: var(--color-white)

          &:focus
            outline: none

          &:active
            background-color: var(--color-havasupai-falls)
            color: var(--color-white)


        &._active
          background-color: var(--color-havasupai-falls)
          color: var(--color-white)

.mb-12px
  margin-bottom: 0.75rem
</style>
