<template>
  <filter-item-wrapper title="Тип" icon="menu_icon2" collapse="collapse-type">
    <template #clear>
      <span
        v-if="existsTurnOnFilterType"
        class="clear-section-filter"
        @click="resetFilterType"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="form-row">
        <div class="col-6 d-flex flex-column justify-content-between">
          <div v-if="isCommerce">
            <label class="filter-modal-label">Тип об'єкта</label>

            <multiselect
              v-model="apt_type"
              placeholder="Почніть вводити:"
              :close-on-select="false"
              :clear-on-select="false"
              :show-no-results="false"
              label="name"
              track-by="id"
              :show-labels="false"
              :options="commerceTypesArray"
              :multiple="true"
              :searchable="false"
              class="selector-width _sm"
            >
              <span slot="noResult">Немає результатів!!!</span>
            </multiselect>
          </div>

          <div v-else>
            <div
              v-for="(label, index) in typesSelected"
              :key="index"
              class="btn-group-toggle form-checkbox-b"
              data-toggle="buttons"
            >
              <label class="btn test-1">
                <input type="checkbox" v-model="apt_type" :value="index" />

                <div class="btn-check-border">
                  <icon name="check_icon" class="btn-check-icon" />
                </div>

                <span>&nbsp;&nbsp;{{ label }}</span>
              </label>
            </div>
          </div>
        </div>

        <div
          class="col-6 d-flex flex-column justify-content-between height-max-content"
        >
          <div v-if="isRooms" class="btn-group-toggle text-center">
            <div class="w-100 number-of-rooms-title">
              {{
                apt_type.length === 1 && apt_type[0].id === 'office_room'
                  ? 'Кількість кабінетів'
                  : 'Кількість кімнат'
              }}
            </div>

            <div
              class="btn-group-toggle container-rooms form-checkbox-b"
              data-toggle="buttons"
            >
              <label v-for="i in filterRooms" :key="i" class="btn">
                <input
                  type="checkbox"
                  v-model="rooms"
                  :checked="rooms.indexOf(+i) > -1"
                  :value="i"
                />

                <div class="btn-check-border">
                  <icon name="check_icon" class="btn-check-icon" />
                </div>

                <span class="desc-item">{{ i === '5%2B' ? '5+' : i }}</span>
              </label>
            </div>
          </div>

          <div
            v-if="isFlat"
            class="btn-group-toggle form-checkbox-b"
            data-toggle="buttons"
          >
            <label class="btn btn--checkbox">
              <input
                type="checkbox"
                autocomplete="off"
                v-model="is_angled"
                :value="false"
              />

              <div class="btn-check-border">
                <icon name="check_icon" class="btn-check-icon" />
              </div>

              <span style="font-size: 12px">Не кутова</span>
            </label>
          </div>

          <div v-if="!isLand">
            <label class="filter-modal-label">Матеріал</label>

            <multiselect
              v-model="material_type"
              placeholder="Матеріал"
              label="name"
              track-by="id"
              :close-on-select="false"
              :clear-on-select="false"
              :show-no-results="false"
              :show-labels="false"
              :options="material_array"
              :multiple="true"
              :searchable="false"
              class="_sm"
            >
            </multiselect>
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import LabelsMixin from '@/mixins/LabelsMixin';
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { ESTATE_TYPES } from '@/helpers/constants';
import Multiselect from 'vue-multiselect';

export default {
  name: 'FilterType',
  mixins: [LabelsMixin],
  components: {
    FilterItemWrapper,
    Multiselect
  },
  data() {
    return {
      filterRooms: ['1', '2', '3', '4', '5%2B']
    };
  },
  methods: {
    ...mapMutations({
      changeAptType: 'CHANGE_APT_TYPE',
      changeRooms: 'CHANGE_ROOMS',
      changeMaterialType: 'CHANGE_MATERIAL_TYPE',
      changeAngled: 'CHANGE_ANGLED',
      resetFilterType: 'RESET_FILTER_TYPE'
    })
  },
  computed: {
    ...mapState({
      buildingType: (state) => state.filter_header.buildingType
    }),
    ...mapGetters({
      existsTurnOnFilterType: 'existsTurnOnFilterType'
    }),
    apt_type: {
      get() {
        return this.$store.state.filter_type.form.apt_type;
      },
      set(data) {
        this.changeAptType(data);
      }
    },
    rooms: {
      get() {
        return this.$store.state.filter_type.form.rooms;
      },
      set(data) {
        this.changeRooms(data);
      }
    },
    material_type: {
      get() {
        return this.$store.state.filter_type.form.material_type;
      },
      set(data) {
        this.changeMaterialType(data);
      }
    },
    is_angled: {
      get() {
        return this.$store.state.filter_type.form.is_angled;
      },
      set(data) {
        this.changeAngled(data);
      }
    },
    typesSelected() {
      switch (this.buildingType) {
        case 'house':
          return this.house_type;
        case 'flat':
          return this.objects_types_double;
        case 'commerce':
          return this.commerce_types;
        case 'land':
          return this.land_types;
        default:
          return [];
      }
    },
    isRooms() {
      if (this.isCommerce && this.apt_type.length !== 0) {
        const aptTypes = [
          'office_room',
          'detached_building',
          'part_of_the_building',
          'shop',
          'beauty_object',
          'health_object',
          'sport_object'
        ];
        return this.apt_type.some((type) => aptTypes.includes(type.id));
      }

      if (this.isLand) return false;

      return true;
    },
    commerceTypesArray() {
      let new_array = [];
      for (const [key, value] of Object.entries(this.commerce_types)) {
        new_array.push({ id: key, name: value });
      }
      return new_array;
    },
    isFlat() {
      return this.buildingType === ESTATE_TYPES.flat;
    },
    isHouse() {
      return this.buildingType === ESTATE_TYPES.house;
    },
    isCommerce() {
      return this.buildingType === ESTATE_TYPES.commerce;
    },
    isLand() {
      return this.buildingType === ESTATE_TYPES.land;
    },
    isParking() {
      return this.buildingType === ESTATE_TYPES.parking;
    }
  }
};
</script>

<style>
.selector-width > .multiselect__content-wrapper {
  width: 200px;
}
</style>
