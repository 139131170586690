<template>
  <div :class="$bem()">
    <upload-images
      ref="upload"
      :class="[
        $bem('form'),
        'p-3',
        {
          _disabled: isMaxLimitReached
        }
      ]"
      :extensions="extensions"
      :accept="accept"
      :multiple="multiple"
      :directory="directory"
      :create-directory="createDirectory"
      :size="size || 0"
      :headers="headers"
      :data="data"
      :drop="drop"
      :drop-directory="dropDirectory"
      :add-index="addIndex"
      :disabled="isMaxLimitReached"
      @input-file="inputFile"
    >
      <h5>Виберіть фото</h5>
    </upload-images>

    <draggable
      v-if="(files && files.length) || loading"
      v-model="files"
      :class="[$bem('container'), 'mt-2 mb-1']"
      draggable=".item"
      @start="onDragStart"
      @end="onDragEnd"
      @unchoose="onUnchoose"
      @update="onUpdate"
    >
      <figure
        :class="[$bem('frame'), 'item']"
        v-for="item in files"
        :key="item.id"
      >
        <span v-if="item.loading" :class="[$bem('frame-loading')]"> </span>

        <img v-else :class="$bem('img')" :src="item.preview || item.thumb" />

        <button
          v-if="item.id && isTouchEnabled"
          :class="$bem('frame-button')"
          @touchstart="removePhoto(item.id)"
        >
          <i :class="[$bem('frame-icon'), 'mdi mdi-trash-can-outline']" />
        </button>

        <button
          v-else-if="item.id && !isTouchEnabled"
          :class="$bem('frame-button')"
          @click="removePhoto(item.id)"
        >
          <i :class="[$bem('frame-icon'), 'mdi mdi-trash-can-outline']" />
        </button>
      </figure>
    </draggable>

    <div class="image-uploader-disclaimer">
      <ul class="image-uploader-disclaimer__list">
        <li>
          <div class="d-flex">
            <span class="mr-1">Кількість:</span>

            <span>до {{ limit }} шт.</span>
          </div>
        </li>

        <li>
          <div class="d-flex">
            <span class="mr-1">Формати:</span>

            <ul class="image-uploader-disclaimer__formats">
              <li v-for="(ext, extIndex) in extensions" :key="extIndex">
                {{ ext
                }}<template v-if="extIndex < extensions.length - 1">,</template>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <small v-if="limitError" :class="[$bem('error')]">{{ limitError }}</small>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Compressor from 'compressorjs';
import heic2any from 'heic2any';
import UploadImages from 'vue-upload-component';
import component from '@/mixins/component';
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';

export default {
  name: 'image-uploader',
  mixins: [component],
  components: {
    UploadImages,
    draggable
  },
  data: () => ({
    extensions: ['gif', 'jpg', 'jpeg', 'png', 'webp', 'heic', 'heif'],
    accept:
      'image/png, image/gif, image/jpeg, image/jpg, image/webp, image/heic, image/heif',
    files: [],
    minSize: 1024,
    size: 1024 * 1024 * 10,
    multiple: true,
    directory: false,
    drop: true,
    dropDirectory: true,
    createDirectory: false,
    addIndex: false,
    thread: 3,
    name: 'file',
    dragging: false,
    loading: false,
    newSorting: []
  }),
  props: {
    images: {
      type: Array,
      default: () => []
    },
    apartmentId: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 999
    }
  },
  computed: {
    isTouchEnabled() {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    limitError() {
      return this.files.length > this.limit ? 'Кількість фото перевищено' : '';
    },
    isEdit() {
      return !!this.apartmentId;
    },
    isMaxLimitReached() {
      return this.files.length >= this.limit;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isEdit) {
        this.files = this.images.map(
          ({ id, image_realty, img_obj, primary }) => ({
            id,
            thumb: img_obj,
            image_realty,
            primary,
            lType: 'backend'
          })
        );
      }
    });
  },
  methods: {
    ...mapActions([
      'handleDeleteImageByApartmentsId',
      'handleCreateImagesByApartmentsId',
      'handleSortingImagesByApartmentsId'
    ]),
    async inputFile(newFile) {
      if (!newFile || this.isMaxLimitReached) return;

      const file = newFile;

      this.loading = true;

      const tempImg = this.createTempImage();

      this.files.push(tempImg);

      try {
        const fileToProcess = await this.prepareFile(file.file);

        const base64Img = await this.getBase64({
          ...file,
          file: fileToProcess
        });

        if (file.lType !== 'backend') {
          if (this.isEdit) {
            const response = await this.handleCreateImagesByApartmentsId({
              realtyId: this.apartmentId,
              images: [base64Img]
            });

            this.updateFilesWithBackendData(response.data, tempImg.id);
          } else {
            this.updateFilesWithFrontendData(base64Img, tempImg.id);

            this.emitBase64Files();
          }
        }
      } catch (error) {
        console.error('Помилка під час додавання зображення:', error);
        this.removeTempImage(tempImg.id);
      } finally {
        this.loading = false;
      }
    },
    async updateFilesWithBackendData(backendFiles, tempId) {
      const tempFileIndex = this.files.findIndex((file) => file.id === tempId);
      if (tempFileIndex !== -1) {
        this.files.splice(
          tempFileIndex,
          1,
          ...backendFiles.map((file) => ({
            id: file.id,
            thumb: file.img_obj,
            image_realty: file.image_realty,
            primary: file.primary,
            lType: 'backend',
            loading: false
          }))
        );
      }
    },
    updateFilesWithFrontendData(base64Img, tempId) {
      const current = this.files.find((f) => f.id === tempId);
      if (current) {
        current.thumb = base64Img;
        current.preview = base64Img;
        current.loading = false;
      }
    },
    async removePhoto(id) {
      const fileToRemove = this.files.find((file) => file.id === id);
      if (!fileToRemove) return;

      if (fileToRemove.lType === 'backend') {
        try {
          await this.handleDeleteImageByApartmentsId({
            realtyId: this.apartmentId,
            id
          });

          this.files = this.files.filter((file) => file.id !== id);
        } catch (error) {
          console.error('Не вдалося видалити зображення:', error);
        }
      } else {
        this.files = this.files.filter((file) => file.id !== id);
        this.emitBase64Files();
      }
    },
    getBase64(fileObj) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileObj.file);

        reader.onload = () => {
          fileObj.base64 = reader.result;
          resolve(fileObj.base64);
        };

        reader.onerror = (error) => reject(error);
      });
    },
    updateSortingImage(realtyId) {
      const backendFiles = this.files.filter(
        (file) => file.lType === 'backend'
      );
      const newSortedImgIds = backendFiles.map(({ id }) => id);
      this.newSorting = newSortedImgIds;

      this.handleSortingImagesByApartmentsId({
        realtyId,
        images: this.newSorting
      });
    },
    emitBase64Files() {
      const base64files = this.files.map((file) => file.thumb);
      this.$emit('input', [...base64files]);
    },
    async convertHEIC(file) {
      try {
        const blob = await heic2any({ blob: file, quality: 0.8 });
        return blob;
      } catch (err) {
        console.error('HEIC conversion failed:', err);
      }
    },
    isHeic(file) {
      if (!file) return;

      return (
        file.type === 'image/heic' ||
        file.type === 'image/heif' ||
        file.name.toLowerCase().endsWith('.heic') ||
        file.name.toLowerCase().endsWith('.heif')
      );
    },
    onDragStart() {
      this.dragging = true;
    },
    onDragEnd() {
      this.dragging = false;
    },
    onUpdate() {
      if (this.isEdit) {
        this.updateSortingImage(this.apartmentId);
      } else {
        this.emitBase64Files();
      }
    },
    onUnchoose() {},
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.5,
          maxWidth: 1024,
          maxHeight: 1024,
          mimeType: 'image/webp',
          success(compressedFile) {
            resolve(compressedFile);
          },
          error(err) {
            console.error('Помилка під час стиснення:', err);
            reject(err);
          }
        });
      });
    },
    createTempImage() {
      return {
        id: uuidv4(),
        thumb: '',
        preview: '',
        lType: 'local',
        loading: true
      };
    },
    removeTempImage(tempId) {
      this.files = this.files.filter((f) => f.id !== tempId);
    },
    async prepareFile(file) {
      const convertedFile = this.isHeic(file)
        ? await this.convertHEIC(file)
        : file;

      const compressedFile = await this.compressImage(convertedFile);

      return this.blobToFile(compressedFile, file.name);
    },
    blobToFile(blob, fileName) {
      return new File([blob], fileName);
    }
  }
};
</script>

<style scoped>
.loading-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #888;
  background-color: #f2f2f2;
  border: 1px dashed #ddd;
  width: 100px;
  height: 100px;
}
</style>
