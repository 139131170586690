export default class SimpleWebSocketClient {
  constructor(url, store, router) {
    this.url = url;
    this.socket = null;
    this.store = store;
    this.router = router;
  }

  connect(message) {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
      this.socket.send(JSON.stringify({ token: message }));
    };

    this.socket.onmessage = (event) => {
      if (event?.data) {
        const message = JSON.parse(event?.data)?.message;
        if (message === 'logout') {
          this.close();
          this.router.push('/logout');
          this.store.commit('EXPIRE_SESSION', true);
        }
      }
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket closed', event.code, event.reason);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ token: message }));
      console.log({ token: message });
    } else {
      console.warn('WebSocket is not open. Message not sent.');
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
    }
  }
}
