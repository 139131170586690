<template>
  <b-navbar
    class="navbar"
    :fixed="isDesktop ? 'top' : 'bottom'"
    :style="dynamicStyles"
    toggleable="lg"
  >
    <mobile-navs
      v-if="isMobile"
      ref="mobileNavs"
      :client-filter-active="sidebarIsOpen"
      :base-filter-active="filtersSidebar"
      @filter-click="handleFilterClick"
      @client-click="toggleSideBar"
    />

    <b-container fluid class="navbar__container-fluid">
      <router-link
        to="/"
        class="navbar__logo navbar-logo"
        :class="{ _loading: loaderObject }"
      >
        <div class="navbar-logo__img">
          <img
            src="@/assets/images/logo/easybase-xs.webp"
            alt="EasyBase"
            width="44"
            height="44"
          />
        </div>

        <span class="navbar-logo__text">EasyBase</span>
      </router-link>

      <div>
        <e-sidebar
          v-if="isFiltersSidebarEnabled"
          v-model="filtersSidebar"
          id="sidebar-1"
          v-click-outside="outsideToggleFiltersSidebar"
          shadow
        >
          <div class="filters-content">
            <Filters @close-filter-sidebar="toggleFiltersSidebar()" />
          </div>
        </e-sidebar>
      </div>

      <!-- Easycoins -->
      <div v-if="isDesktop" class="navbar__coins">
        <span>{{ easycoins }} Easycoins</span>
      </div>

      <!-- Header for desktop -->
      <div v-if="!this.$route.meta.menu" class="res-navbar" ref="resNavbar">
        <div class="res-navbar__wrap navbar-header-mobile">
          <div class="res-navbar__logo">
            <img
              src="@/assets/images/logo/EasyBase_logo_2.png"
              alt="EasyBase"
            />

            <!-- <img src="@/assets/images/logo/new-year-logo.png" alt="EasyBase" /> -->
          </div>

          <div
            class="nav-link-border nav-item d-lg-none"
            style="margin-bottom: auto; margin-top: auto"
          >
            <span style="font-size: 16px; color: #27a39f">
              {{ userMe.easy_coins }} Easycoins
            </span>
          </div>

          <ul class="ml-md-auto navbar-nav align-content-center">
            <li class="nav-link-border nav-item">
              <router-link active-class="active" to="/base" class="nav-link">
                <icon name="cloud_icon" class="filter-item-icon d-sm-none" />
                <span>База</span>
              </router-link>
            </li>

            <li v-if="isDesktop" class="nav-link-border nav-item mob-none">
              <router-link active-class="active" to="/archive" class="nav-link">
                <icon name="cloud_icon" class="filter-item-icon d-sm-none" />
                <span>Архів</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item d-lg-none">
              <router-link active-class="active" to="/base" class="nav-link">
                <icon name="cloud_icon" class="filter-item-icon d-sm-none" />
                <span>База</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item d-lg-none">
              <router-link active-class="active" to="/archive" class="nav-link">
                <icon name="archive_icon" class="filter-item-icon d-sm-none" />
                <span>Архів</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item d-lg-none">
              <router-link
                active-class="active"
                to="/profile#payments"
                class="nav-link"
              >
                <icon name="pay_icon" class="filter-item-icon d-sm-none" />
                <span>Оплата</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item term-block">
              <router-link active-class="active" to="/search" class="nav-link">
                <icon name="search" class="filter-item-icon d-sm-none" />
                <span>Мої пошуки</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item">
              <router-link active-class="active" to="/objects" class="nav-link">
                <icon name="builds_icon" class="filter-item-icon d-sm-none" />
                <span>Мої об'єкти</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item">
              <router-link active-class="active" to="/profile" class="nav-link">
                <icon name="profile_icon" class="filter-item-icon d-sm-none" />
                <span>Мої дані</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item">
              <router-link active-class="active" to="/stock" class="nav-link">
                <icon name="archive_icon" class="filter-item-icon d-sm-none" />
                <span>EasyCoin</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item">
              <router-link active-class="active" to="/useful" class="nav-link">
                <span>Kopисне</span>
              </router-link>
            </li>

            <li class="nav-link-spr d-lg-inline-block">
              <a
                class="nav-link"
                target="_blank"
                href="https://invite.viber.com/?g2=AQAK9IpHpaTlGU%2BoaVtqM8PR7n2OiVrVC69g1LLx7sbtzYdN8w9hYEdqUo7%2BR%2BNd"
              >
                <img
                  src="@/assets/images/icons-svg/viber-accent.svg"
                  width="24"
                  height="24"
                />
              </a>
            </li>

            <li class="nav-link-spr d-lg-inline-block">
              <a
                class="nav-link"
                target="_blank"
                href="https://instagram.com/easybase.dnepr"
              >
                <img
                  src="@/assets/images/icons-svg/instagram-accent.svg"
                  width="24"
                  height="24"
                />
              </a>
            </li>

            <li v-if="isDesktop" class="nav-item">
              <notification-tooltip @open-modal="openModal($event)" />
            </li>

            <li class="nav-item dropdown dm-nav-dropdown d-none d-lg-block">
              <router-link
                active-class="active"
                to="/profile"
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
              >
                <img src="@/assets/images/icons-webp/login.webp" />
              </router-link>

              <div
                class="dropdown-menu dm-dropdown-menu dm-dropdown-menu-left"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/logout" class="dropdown-item">
                  Вийти
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="res-navbar res-filter d-lg-none" ref="resFilter">
        <div v-if="isMobile" class="res-navbar__wrap">
          <div v-if="$route.name === 'objects'" class="clients">
            <FilterObjects v-click-outside="outsideToggleFiltersSidebar" />
          </div>

          <div v-else-if="shouldShowClientsFilters" class="clients">
            <ClientFilter v-click-outside="outsideToggleSidebar" />
          </div>
        </div>
      </div>

      <div ref="resNavbarBackdropFilter" class="res-navbar-wrapper"></div>
    </b-container>

    <popup-referral />

    <notification-popup v-model="model" :notification="notification" />
  </b-navbar>
</template>

<script>
import ESidebar from '../../../elements/e-sidebar';
import ClientFilter from '../../clients/ClientFilter';
const ALLOWED_ROUTE_FOR_CLIENTS_FILTER = ['search', 'client'];
import { mapState } from 'vuex';
// eslint-disable-next-line import/no-unresolved
import api from '@/api';
// eslint-disable-next-line import/no-unresolved
import popupReferral from '@/components/popups/popup-referral';
import notificationTooltip from './components/notification-tooltip';
import notificationPopup from './components/notification-popup';
import ResponsiveMixin from '@/mixins/responsive';

export default {
  name: 'Navbar',
  mixins: [ResponsiveMixin],
  components: {
    ESidebar,
    notificationPopup,
    notificationTooltip,
    ClientFilter,
    Filters: () => import('../../app/Filters'),
    popupReferral,
    FilterObjects: () =>
      import('../../app/filters/filter-my-objects/FilterObjects'),
    MobileNavs: () => import('../../app/mobile-navs')
  },
  data() {
    return {
      isOpenMenuSideBar: false,
      easycoins: 0,
      model: false,
      show: false,
      notification: false,
      sidebarIsOpen: false,
      filtersSidebar: false,
      filtersObjectSidebar: false,
      filtersClientSidebar: false
    };
  },
  computed: {
    ...mapState({
      loaderObject: (state) => state.main.loaderObject,
      userMe: (state) => state.profile.userMe
    }),
    dynamicStyles() {
      return this.isMobile ? { height: '0', padding: '0' } : {};
    },
    shouldShowClientsFilters() {
      return ALLOWED_ROUTE_FOR_CLIENTS_FILTER.includes(this.$route.name);
    },
    pageTitle: ({ $route: { meta } }) => meta.title,
    isFiltersSidebarEnabled() {
      return ['home', 'archive'].includes(this.$route.name);
    }
  },
  watch: {
    $route() {
      this.sidebarIsOpen = false;
      this.filtersSidebar = false;
      this.$refs.resNavbarBackdropFilter?.classList?.remove('show');
      this.$refs.resFilter?.classList?.remove('show');
    }
  },
  created() {
    api.getEasycoins().then((res) => {
      if (res.data && res.data.easycoins) {
        this.easycoins = res.data.easycoins;
      }
    });
  },
  methods: {
    openModal(notification) {
      this.model = true;
      this.notification = notification;
    },
    toggleSideBar() {
      this.$refs.resNavbarBackdropFilter?.classList?.toggle('show');
      this.$refs.resFilter?.classList?.toggle('show');

      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
    handleFilterClick(e) {
      e.stopPropagation();

      this.toggleFiltersSidebar();
    },
    toggleFiltersSidebar() {
      this.$refs.resNavbarBackdropFilter?.classList?.toggle('show');
      this.$refs.resFilter?.classList?.toggle('show');

      this.filtersSidebar = !this.filtersSidebar;
    },
    outsideToggleSidebar(e) {
      if (this.sidebarIsOpen) {
        if (
          this.$refs.mobileNavs &&
          this.$refs.mobileNavs.$el.contains(e.target)
        ) {
          return;
        }

        this.toggleSideBar();
      }
    },
    outsideToggleFiltersSidebar(e) {
      if (this.filtersSidebar) {
        if (
          this.$refs.mobileNavs &&
          this.$refs.mobileNavs.$el.contains(e.target)
        ) {
          return;
        }

        this.toggleFiltersSidebar();
      }
    }
  }
};
</script>
