<template>
  <div class="filter-header">
    <div class="filter-head-container">
      <!-- Відступ для стилізації -->
      <div class="filter-marg d-lg-none"></div>

      <!-- Заголовок сторінки -->
      <h2 class="filter-main-title" id="page-title">
        {{ pageTitle }}
      </h2>

      <!-- Відступ для стилізації -->
      <div class="filter-marg"></div>

      <!-- Назва розділу -->
      <div class="base-text">Мої об'єкти</div>

      <!-- Dropdown для вибору типу об'єкта -->
      <b-dropdown
        class="dropdown-apartments ml-1"
        :text="buildingTypeLabel"
        aria-label="Виберіть тип об'єкта"
      >
        <b-dropdown-item
          v-for="(type, typeKey) in types"
          :key="typeKey"
          id="dropdown-menu"
          role="menuitem"
          @click="updateBuildingType(typeKey)"
        >
          <div class="d-flex align-items-center">
            <span class="mr-1">{{ type }}</span>

            <span class="new-label" v-if="typeKey === 'parking'">Нове</span>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { TYPE_CONSTANTS_PLURAL } from '@/helpers/constants';
import LabelsMixin from '@/mixins/LabelsMixin';

export default {
  name: 'FilterObjects',
  mixins: [LabelsMixin],
  computed: {
    ...mapState({
      buildingType: (state) => state.my_objects.buildingType
    }),
    pageTitle() {
      return this.$route.meta.title || 'Фільтр';
    },
    buildingTypeLabel() {
      return TYPE_CONSTANTS_PLURAL[this.buildingType] || 'Не вказано';
    }
  },
  mounted() {
    const { objectType } = this.$route.params;

    if (objectType && objectType !== this.buildingType) {
      this.updateBuildingType(objectType);
    }
  },
  methods: {
    ...mapActions(['handleGetObjects']),
    updateBuildingType(type) {
      if (type === this.buildingType) return;

      this.$store.commit('SET_BUILDING_TYPE_OBJECT', type);
      this.handleGetObjects();
    }
  }
};
</script>
