import SimpleWebSocketClient from '@/websocket.js';
import store from '@/store';
import router from '@/router';

let wsClient = null;

export async function initWebSocket() {
  const wsURL = `wss://api.easybase.com.ua/ws/user-session`;

  wsClient = new SimpleWebSocketClient(wsURL, store, router);
  const token = localStorage.getItem('tokenSessionId');
  await wsClient.connect(token);
  return wsClient;
}

export function closeWebSocket() {
  if (wsClient) {
    wsClient.close();
    wsClient = null;
  }
}

export function getWebSocketClient() {
  return wsClient;
}
