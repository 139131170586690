const getDefaultState = () => {
  return {
    form: {
      parking_type: [],
      vehicle_places: [],
      material: []
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterParkingType(state) {
    return (
      state.form.parking_type.length ||
      state.form.vehicle_places.length ||
      state.form.material.length
    );
  }
};

export const mutations = {
  CHANGE_FILTER_PARKING_TYPE(state, payload) {
    state.form.parking_type = payload;
  },
  CHANGE_FILTER_PARKING_VEHICLE_PLACES(state, payload) {
    state.form.vehicle_places = payload;
  },
  CHANGE_FILTER_PARKING_MATERIAL_TYPE(state, payload) {
    state.form.material = payload;
  },
  RESET_FILTER_PARKING_TYPE(state) {
    Object.assign(state, getDefaultState());
  }
};
