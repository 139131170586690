<template>
  <div v-if="renderComponent" :class="$bem()">
    <FilterHeader @forceRerender="forceRerender" />

    <div :class="$bem('filter-options')" :style="filterOptionsStyle">
      <FilterSort @changeSortable="filter" />

      <FilterAddress />

      <FilterType v-if="!isLand && !isParking" />

      <FilterLandsPurpose v-if="isLand" />

      <FilterParkingType v-if="isParking" />

      <FilterNumberOfStoreys v-if="isNumberOfStoreys" />

      <FilterSquare />

      <FilterOptions v-if="isOptions" />

      <FilterCondition v-if="!isLand && !isParking" />

      <FilterCommunications v-if="isHouse" />

      <FilterLandsCadastralNumber v-if="isLand" />

      <FilterLandsCommunications v-if="isLand" />

      <FilterParkingComplectations v-if="isParking" />

      <FilterParkingCommunications v-if="isParking" />

      <FilterPrice />

      <FilterPhone />

      <FilterOption />

      <FilterLabel />

      <FilterDate />

      <FilterSource />
    </div>

    <div :class="$bem('filter-actions')">
      <div class="filters__footer filters-footer">
        <div class="filters-footer__row">
          <div class="filters-footer__col">
            <button class="btn-filter" @click.prevent="filterClear">
              Очистити пошук
            </button>
          </div>

          <div class="filters-footer__col">
            <button class="btn-filter" @click.prevent="filter">
              Застосувати
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ESTATE_TYPES } from '@/helpers/constants';
import FilterHeader from '@/components/app/filters/filter-header/FilterHeader';
import FilterAddress from '@/components/app/filters/filter-address/FilterAddress';
import FilterType from '@/components/app/filters/filter-type/FilterType';
import FilterNumberOfStoreys from '@/components/app/filters/filter-number-of-storeys/FilterNumberOfStoreys';
import FilterSquare from '@/components/app/filters/filter-square/FilterSquare';
import FilterCondition from '@/components/app/filters/filter-condition/FilterCondition';
import FilterCommunications from '@/components/app/filters/filter-comunication/FilterCommunications';
import FilterPrice from '@/components/app/filters/filter-price/FilterPrice';
import FilterPhone from '@/components/app/filters/filter-phone/FilterPhone';
import FilterLabel from '@/components/app/filters/filter-label/FilterLabel';
import FilterDate from '@/components/app/filters/filter-date/FilterDate';
import FilterSource from '@/components/app/filters/filter-source/FilterSource';
import FilterSort from '@/components/app/filters/filter-sort';
import FilterOptions from '@/components/app/filters/filter-options/FilterOptions';
import FilterOption from '@/components/app/filters/filter-option/FilterOption';
import FilterLandsPurpose from '@/components/app/filters/filter-lands-purpose/FilterLandsPurpose';
import FilterLandsCommunications from '@/components/app/filters/filter-lands-comunications/FilterLandsCommunications';
import FilterLandsCadastralNumber from '@/components/app/filters/filter-lands-cadastral-number/FilterLandsCadastralNumber';
import FilterParkingCommunications from '@/components/app/filters/filter-parking-communications/FilterParkingCommunications';
import FilterParkingComplectations from '@/components/app/filters/filter-parking-complectations/FilterParkingComplectations';
import FilterParkingType from '@/components/app/filters/filter-parking-type/FilterParkingType';
import component from '@/mixins/component';

const CATALOG_OPTIONS_OFFSET = 196;

export default {
  name: 'filters',
  mixins: [component],
  components: {
    FilterHeader,
    FilterAddress,
    FilterType,
    FilterNumberOfStoreys,
    FilterSquare,
    FilterCondition,
    FilterCommunications,
    FilterPrice,
    FilterPhone,
    FilterLabel,
    FilterDate,
    FilterSource,
    FilterSort,
    FilterOptions,
    FilterOption,
    FilterLandsPurpose,
    FilterLandsCommunications,
    FilterLandsCadastralNumber,
    FilterParkingType,
    FilterParkingCommunications,
    FilterParkingComplectations
  },
  data() {
    return {
      renderComponent: true,
      optionsHeight: this.calculateOptionsHeight()
    };
  },
  computed: {
    ...mapState({
      buildingType: (state) => state.filter_header.buildingType,
      aptTypes: (state) => state.filter_type.form.apt_type
    }),
    isNumberOfStoreys() {
      if (this.isLand || this.isParking) return;

      if (this.aptTypes.length !== 0 && this.isCommerce) {
        let aptTypes = [
          'recreation_center_or_hotel',
          'industrial_premise',
          'free_appointment_premise',
          'MAF',
          'autoservice',
          'farm',
          'trading_area',
          'production_object'
        ];

        return !this.aptTypes.every((type) => aptTypes.includes(type.id));
      }

      return true;
    },
    isOptions() {
      if (this.aptTypes.length !== 0 && this.isCommerce) {
        let aptTypes = ['MAF', 'farm', 'trading_area'];
        return !this.aptTypes.every((type) => aptTypes.includes(type.id));
      }

      return this.isCommerce;
    },
    isFlat() {
      return this.buildingType === ESTATE_TYPES.flat;
    },
    isHouse() {
      return this.buildingType === ESTATE_TYPES.house;
    },
    isCommerce() {
      return this.buildingType === ESTATE_TYPES.commerce;
    },
    isLand() {
      return this.buildingType === ESTATE_TYPES.land;
    },
    isParking() {
      return this.buildingType === ESTATE_TYPES.parking;
    },
    filterOptionsStyle() {
      return { height: `${this.optionsHeight}px` };
    }
  },
  mounted() {
    this.addResizeListener();
  },
  beforeDestroy() {
    this.removeResizeListener();
  },
  methods: {
    ...mapMutations({
      setPage: 'SET_PAGE'
    }),
    ...mapActions(['getObjects']),
    filter() {
      this.$store.commit('SET_SEARCH_ID', '');
      this.setPage(1);
      this.getObjects('flats');
      this.forceRerender();

      this.$emit('close-filter-sidebar');
    },
    filterClear() {
      this.$store.dispatch('resetFilters');
      this.forceRerender();
      this.filter();
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    addResizeListener() {
      window.addEventListener('resize', this.updateOptionsHeight);
    },
    removeResizeListener() {
      window.removeEventListener('resize', this.updateOptionsHeight);
    },
    calculateOptionsHeight() {
      return window.innerHeight - CATALOG_OPTIONS_OFFSET;
    },
    updateOptionsHeight() {
      this.optionsHeight = this.calculateOptionsHeight();
    }
  }
};
</script>

<style lang="sass">
.filters
  &__filter-options
    padding-top: 1rem
    overflow-y: auto
    overflow-x: hidden

    &::-webkit-scrollbar
      width: 0

  &__filter-actions
    margin-top: auto
    width: 100%
    padding: 0.625rem 0.625rem

.filters-footer
  &__row
    display: flex
    margin: 0 -0.375rem

  &__col
    flex: 0 0 50%
    max-width: 50%
    padding: 0 0.375rem

    .btn-filter
      display: block
      width: 100%
</style>
