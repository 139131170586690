export const squareSuggestedTags = {
  flat: {
    sell: [
      {
        name: 'До 30 м²',
        range: { from: 0, to: 30 }
      },
      {
        name: 'Від 40 до 50 м²',
        range: { from: 40, to: 50 }
      },
      {
        name: 'від 50 до 73 м²',
        range: { from: 50, to: 73 }
      },
      {
        name: 'Від 73 м²',
        range: { from: 73, to: '' }
      },
      {
        name: 'Від 100 м²',
        range: { from: 100, to: '' }
      }
    ],
    rent: [
      {
        name: 'До 30 м²',
        range: { from: 0, to: 30 }
      },
      {
        name: 'Від 40 до 50 м²',
        range: { from: 40, to: 50 }
      },
      {
        name: 'від 50 до 73 м²',
        range: { from: 50, to: 73 }
      },
      {
        name: 'Від 73 м²',
        range: { from: 73, to: '' }
      },
      {
        name: 'Від 100 м²',
        range: { from: 100, to: '' }
      }
    ]
  },
  house: {
    sell: [
      {
        name: 'До 30 м²',
        range: { from: 0, to: 30 }
      },
      {
        name: 'Від 40 до 50 м²',
        range: { from: 40, to: 50 }
      },
      {
        name: 'від 50 до 73 м²',
        range: { from: 50, to: 73 }
      },
      {
        name: 'Від 73 м²',
        range: { from: 73, to: '' }
      },
      {
        name: 'Від 100 м²',
        range: { from: 100, to: '' }
      },
      {
        name: 'Від 200 м²',
        range: { from: 200, to: '' }
      },
      {
        name: 'Від 300 м²',
        range: { from: 300, to: '' }
      }
    ],
    rent: [
      {
        name: 'До 30 м²',
        range: { from: 0, to: 30 }
      },
      {
        name: 'Від 40 до 50 м²',
        range: { from: 40, to: 50 }
      },
      {
        name: 'від 50 до 73 м²',
        range: { from: 50, to: 73 }
      },
      {
        name: 'Від 73 м²',
        range: { from: 73, to: '' }
      },
      {
        name: 'Від 100 м²',
        range: { from: 100, to: '' }
      },
      {
        name: 'Від 200 м²',
        range: { from: 200, to: '' }
      },
      {
        name: 'Від 300 м²',
        range: { from: 300, to: '' }
      }
    ]
  },
  commerce: {
    rent: [],
    sell: []
  },
  land: {
    rent: [],
    sell: []
  },
  parking: {
    rent: [],
    sell: []
  }
};
