// src/mixins/responsive.js

export default {
  computed: {
    isDesktop({ $mq }) {
      return $mq === 'lg' || $mq === 'xl';
    },
    isMobile({ $mq }) {
      return $mq === 'xs' || $mq === 'sm' || $mq === 'md';
    }
  }
};
