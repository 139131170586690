<template>
  <div :class="$bem()">
    <div v-if="isLand" :class="$bem('blocks')">
      <div :class="$bem('block')">
        {{ cadastralNumber }}
      </div>
    </div>

    <!-- Тип паркінгу, матеріал охорона -->
    <template v-if="isParking">
      <div :class="$bem('blocks')">
        <div v-if="apartment.parking_type" :class="$bem('block')">
          {{ parkingTypeLabel }}
        </div>

        <div v-if="apartment.material" :class="$bem('block')">
          {{ parkingMaterialTypeLabel }}
        </div>
      </div>

      <div v-if="isParkingSecurity" :class="$bem('blocks')">
        <div :class="$bem('block')">територія під охороною</div>
      </div>
    </template>

    <!-- Тип земельної ділянки -->
    <div v-if="isLand && apartment.purpose" :class="$bem('blocks')">
      <div :class="$bem('block')">
        {{ landPurposeLabel }}
      </div>
    </div>

    <div v-if="!isLand && !isParking" :class="$bem('blocks')">
      <div v-if="apartment.apt_type" :class="$bem('block')">
        {{ getAptType }}
      </div>

      <div
        v-if="
          apartment.material_type && material_labels[apartment.material_type]
        "
        :class="$bem('block')"
      >
        {{ material_labels[apartment.material_type] }}
      </div>
    </div>

    <table v-if="!isLand && !isParking" :class="$bem('options-table')">
      <tr>
        <td v-if="apartment.rooms || apartment.rooms || isHouse">
          <img
            src="@/assets/icons/rooms.svg"
            alt=""
            loading="lazy"
            width="14"
            height="12"
          />

          <span>
            {{
              apartment.rooms
                ? `${apartment.rooms} ${roomEnding}`
                : 'Не вказано'
            }}
          </span>
        </td>

        <td v-if="square">
          <img
            src="@/assets/icons/square.svg"
            alt="square-icon"
            loading="lazy"
            width="16"
            height="16"
          />

          <span v-html="square" />
        </td>
      </tr>

      <tr>
        <td v-if="apartment.floor || apartment.floors">
          <img
            src="@/assets/icons/floor.svg"
            alt=""
            loading="lazy"
            width="16"
            height="16"
          />

          <span>
            {{ apartment.floor ? `${apartment.floor} поверх з ` : '' }}
            {{ apartment.floors }}
            <template v-if="isHouse">поверховість</template>
          </span>
        </td>

        <td v-if="!!repairItem">
          <img
            src="@/assets/icons/repair.svg"
            alt=""
            loading="lazy"
            width="16"
            height="16"
          />

          <span>{{ repairItem }}</span>
        </td>
      </tr>
    </table>

    <table v-if="isLand && square" :class="$bem('options-table')">
      <tr>
        <td>
          <div class="d-flex flex-wrap align-items-center">
            <div class="d-inline-flex align-items-center mr-2">
              <img
                src="@/assets/icons/lands-square.svg"
                alt="square-icon"
                loading="lazy"
                width="16"
                height="16"
                class="mr-1"
              />

              <span v-html="square" class="lh-1" />
            </div>

            <div class="d-inline-flex items-center">
              <div class="d-block mr-2">
                Довж.
                <span class="text-nowrap">
                  {{ getIntValue(apartment.length) || '-' }} м
                </span>
              </div>

              <div class="d-block">
                Шир.
                <span class="text-nowrap">
                  {{ getIntValue(apartment.width) || '-' }} м
                </span>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>

    <table
      v-if="isParking && (square || apartment.vehicle_places)"
      :class="$bem('options-table')"
    >
      <tr>
        <td v-if="square">
          <div class="d-flex align-items-center">
            <img
              src="@/assets/icons/square.svg"
              alt="square-icon"
              loading="lazy"
              width="16"
              height="16"
              class="mr-1"
            />

            <span v-html="square" class="lh-1" />
          </div>
        </td>

        <td v-if="apartment.vehicle_places">
          <div class="d-flex align-items-center">
            <img
              src="@/assets/images/icons-svg/mobile-base.svg"
              alt=""
              loading="lazy"
              width="16"
              height="16"
              class="object-contain mr-2"
            />

            <span class="lh-1">{{ apartment.vehicle_places }} авто</span>
          </div>
        </td>
      </tr>
    </table>

    <div :class="$bem('divider')"></div>

    <table :class="$bem('price-table')">
      <tr>
        <td>
          <div :class="$bem('price-date')">
            <div>Актуально на:</div>

            <div>
              {{
                apartment.updated != null ? apartment.updated.split(' ')[0] : ''
              }}
            </div>
          </div>
        </td>

        <td>
          <div class="d-flex justify-content-end align-items-center">
            <div :class="$bem('price')">
              {{ apartment.price }} {{ currency }}
            </div>

            <button
              :id="`tooltip-target-price-mobile-${apartment.id}`"
              :class="$bem('info-btn')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                :class="$bem('svg')"
              >
                <path
                  d="M7 6.27778V9.88889M7 13.5C6.14641 13.5 5.30117 13.3319 4.51256 13.0052C3.72394 12.6786 3.00739 12.1998 2.40381 11.5962C1.80023 10.9926 1.32144 10.2761 0.994783 9.48744C0.668128 8.69883 0.5 7.85359 0.5 7C0.5 6.14641 0.668128 5.30117 0.994783 4.51256C1.32144 3.72394 1.80023 3.00739 2.40381 2.40381C3.00739 1.80022 3.72394 1.32144 4.51256 0.994783C5.30117 0.668127 6.14641 0.5 7 0.5C8.72391 0.5 10.3772 1.18482 11.5962 2.40381C12.8152 3.62279 13.5 5.27609 13.5 7C13.5 8.72391 12.8152 10.3772 11.5962 11.5962C10.3772 12.8152 8.72391 13.5 7 13.5ZM7.03611 4.11111V4.18333H6.96389V4.11111H7.03611Z"
                  stroke="#00D2CD"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          <span v-if="apartment.price_old">
            <span :class="$bem('old-price')">
              {{ apartment.price_old }} {{ currency }}
            </span>
          </span>

          <b-tooltip
            :target="`tooltip-target-price-mobile-${apartment.id}`"
            triggers="click"
            placement="bottom"
            :custom-class="$bem('price-popup')"
          >
            {{ pricePerMeter }}
          </b-tooltip>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { ukrainianEndings } from '@/utils/ukrainian-endings.js';
import { getIntegerValue } from '@/utils/get-integer-value';
import {
  APT_TYPE_CONSTANTS_DOUBLE,
  HOUSE_TYPES,
  OBJECT_TYPES,
  LAND_TYPES,
  PARKING_TYPES,
  PARKING_MATERIAL_TYPES,
  ESTATE_TYPES
} from '@/helpers/constants';

import component from '@/mixins/component';

export default {
  name: 'apartment-table',
  mixins: [component],
  props: [
    'apartment',
    'material_labels',
    'house_type',
    'square',
    'repairItem',
    'short_commerce_types',
    'commerce_types',
    'pricePerMeter'
  ],
  computed: {
    getAptType() {
      switch (this.apartment.type) {
        case ESTATE_TYPES.flat:
          return APT_TYPE_CONSTANTS_DOUBLE[this.apartment.apt_type];
        case ESTATE_TYPES.house:
          return HOUSE_TYPES[this.apartment.apt_type];
        case ESTATE_TYPES.commerce:
          return OBJECT_TYPES[this.apartment.apt_type];
      }
    },
    roomEnding() {
      if (!this.apartment.rooms) return '';

      return ukrainianEndings(this.apartment.rooms, [
        'кімната',
        'кімнати',
        'кімнат'
      ]);
    },
    currency() {
      return this.apartment.business_type === 'rent' ? 'грн' : '$';
    },
    isFlat() {
      return this.apartment.type === ESTATE_TYPES.flat;
    },
    isHouse() {
      return this.apartment.type === ESTATE_TYPES.house;
    },
    isCommerce() {
      return this.apartment.type === ESTATE_TYPES.commerce;
    },
    isLand() {
      return this.apartment.type === ESTATE_TYPES.land;
    },
    isParking() {
      return this.apartment.type === ESTATE_TYPES.parking;
    },
    cadastralNumber() {
      return this.apartment.cadastral_number
        ? this.apartment.cadastral_number
        : '––––––––––:––:–––:––––';
    },
    landPurposeLabel() {
      return this.apartment.purpose ? LAND_TYPES[this.apartment.purpose] : '';
    },
    parkingTypeLabel() {
      return this.apartment.parking_type
        ? PARKING_TYPES[this.apartment.parking_type]
        : '';
    },
    parkingMaterialTypeLabel() {
      return this.apartment.material
        ? PARKING_MATERIAL_TYPES[this.apartment.material]
        : '';
    },
    isParkingSecurity() {
      return this.apartment.complectations.includes('security');
    }
  },
  methods: {
    getIntValue(value) {
      return getIntegerValue(value) || 0;
    }
  }
};
</script>

<style lang="sass">
.apartment-table
  &__blocks
    display: flex
    flex-wrap: wrap
    gap: 0.25rem
    margin-bottom: 0.375rem

  &__block
    border-radius: 5px
    width: fit-content
    padding: 0.25rem 0.375rem
    background-color: $light-blue-dark
    color: $black
    font-size: 13px
    line-height: 1
    text-transform: lowercase

  table
    width: 100%

  &__price-table
    td:last-child
      text-align: right

  &__options-table
    font-size: 13px
    font-weight: 300
    td
      min-width: 120px
    img
      margin-right: 2px

  &__divider
    border-top: 1px solid $border
    width: calc(100% - 5px)
    margin: 5px auto 2px

  &__price-popup
    .tooltip-inner
      background: $active_text_color
      font-size: 14px
      font-weight: 300
      padding: 10px

    .arrow
      &:before
        border-bottom-color: $active_text_color !important

  &__svg
    fill: transparent

  &__price
    font-size: 15px
    font-weight: 700

  &__old-price
    text-decoration: line-through
    font-size: 13px
    margin-right: 21px

  &__price-date
    font-size: 13px
    font-weight: 300

  &__info-btn
    display: flex
    align-items: center
    justify-content: center
    outline: none
    border: none
    padding: 0.5rem 0.75rem
    background: none
</style>
