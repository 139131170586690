<template>
  <filter-item-wrapper
    title="Тип"
    icon="menu_icon2"
    collapse="collapse-parking-type"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterParkingType"
        class="clear-section-filter"
        @click="resetFilterParkingType"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="row">
        <div class="col-12 mb-3">
          <ul class="list-unstyled mb-0">
            <li
              v-for="(typeLabel, typeValue) in parkingTypesOptions"
              :key="typeValue"
              class="btn-group-toggle form-checkbox-b"
              data-toggle="buttons"
            >
              <label class="btn pl-0">
                <input
                  type="checkbox"
                  v-model="parking_type"
                  :value="typeValue"
                />

                <div class="btn-check-border">
                  <icon name="check_icon" class="btn-check-icon" />
                </div>

                <span>&nbsp;&nbsp;{{ typeLabel }}</span>
              </label>
            </li>
          </ul>
        </div>

        <div class="col-12 mb-3">
          <label for="" class="d-block mb-1">Кількість паркомісць</label>

          <div class="btn-group-toggle form-checkbox-b d-flex flex-wrap">
            <label
              v-for="vahiclePlace in filterVehiclePlaces"
              :key="vahiclePlace"
              class="btn pl-0"
            >
              <input
                type="checkbox"
                v-model="vehicle_places"
                :checked="vehicle_places.indexOf(+vahiclePlace) > -1"
                :value="vahiclePlace"
              />

              <div class="btn-check-border">
                <icon name="check_icon" class="btn-check-icon" />
              </div>

              <span class="desc-item">
                {{ vahiclePlace === '5%2B' ? '5+' : vahiclePlace }}
              </span>
            </label>
          </div>
        </div>

        <div class="col-6">
          <div>
            <label for="filterParkingMaterialTypeControl">Матеріал</label>

            <multiselect
              v-model="material"
              id="filterParkingMaterialTypeControl"
              label="name"
              track-by="id"
              :close-on-select="false"
              :clear-on-select="false"
              :show-no-results="false"
              :show-labels="false"
              :multiple="true"
              :searchable="false"
              :options="materialTypesOptions"
              placeholder="Матеріал"
              class="_sm"
            >
            </multiselect>
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { PARKING_MATERIAL_TYPES, PARKING_TYPES } from '@/helpers/constants';
import { objectToArray } from '@/utils/object-to-array';
import LabelsMixin from '@/mixins/LabelsMixin';
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import Multiselect from 'vue-multiselect';

export default {
  name: 'FilterParkingType',
  mixins: [LabelsMixin],
  components: {
    FilterItemWrapper,
    Multiselect
  },
  data() {
    return {
      filterVehiclePlaces: ['1', '2', '3', '4', '5%2B']
    };
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterParkingType: 'existsTurnOnFilterParkingType'
    }),
    parking_type: {
      get() {
        return this.$store.state.filter_parking_type.form.parking_type;
      },
      set(data) {
        this.changeFilterParkingType(data);
      }
    },
    vehicle_places: {
      get() {
        return this.$store.state.filter_parking_type.form.vehicle_places;
      },
      set(data) {
        this.changeVehiclePlaces(data);
      }
    },
    material: {
      get() {
        return this.$store.state.filter_parking_type.form.material;
      },
      set(data) {
        this.changeMaterialType(data);
      }
    },
    parkingTypesOptions() {
      return PARKING_TYPES || [];
    },
    materialTypesOptions() {
      return objectToArray(PARKING_MATERIAL_TYPES, true) || [];
    }
  },
  methods: {
    ...mapMutations({
      changeFilterParkingType: 'CHANGE_FILTER_PARKING_TYPE',
      changeVehiclePlaces: 'CHANGE_FILTER_PARKING_VEHICLE_PLACES',
      changeMaterialType: 'CHANGE_FILTER_PARKING_MATERIAL_TYPE',
      resetFilterParkingType: 'RESET_FILTER_PARKING_TYPE'
    })
  }
};
</script>
