// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import './assets/styles/_variables.sass';
import VueLazyLoad from 'vue-lazyload';
import BootstrapVue from 'bootstrap-vue';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Notifications from 'vue-notification';
import Vue2TouchEvents from 'vue2-touch-events';
import vClickOutside from 'v-click-outside';
import Vuelidate from 'vuelidate';
import VueFileAgent from 'vue-file-agent';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueClipboard from 'vue-clipboard2';
import SvgIcon from 'vue-svgicon';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-file-agent/dist/vue-file-agent.css';
import './plugins/vue-mq';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import VueSimplebar from 'vue-simplebar';
import VueMask from 'v-mask';
import initGTM from '@/plugins/gtm';

Vue.use(VueMask);
Vue.use(VueSimplebar);
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_PUBLIC_CAPTCHA_KEY });
Vue.use(VueLazyLoad);
Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(Vue2TouchEvents);
Vue.use(Vuelidate);
Vue.use(VueFileAgent);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(vClickOutside);
Vue.use(SvgIcon, {
  tagName: 'icon',
  duration: 99999
});

// GTM
initGTM();

Vue.config.productionTip = false;
Vue.config.silent = process.env.VUE_APP_CONFIG_SILENT;

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app');
