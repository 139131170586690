export const priceSuggestedTags = {
  flat: {
    sell: [
      {
        name: 'до 40 тис $',
        range: { from: 0, to: 40000 }
      },
      {
        name: 'від 40 до 50 тис $',
        range: { from: 40000, to: 50000 }
      },
      {
        name: 'від 50 до 70 тис $',
        range: { from: 50000, to: 70000 }
      },
      {
        name: 'від 70 до 100 тис $',
        range: { from: 70000, to: 100000 }
      },
      {
        name: 'від 100 тис $',
        range: { from: 100000, to: '' }
      }
    ],
    rent: [
      {
        name: 'до 6 тис грн',
        range: { from: 0, to: 6000 }
      },
      {
        name: 'від 6 до 10 тис грн',
        range: { from: 6000, to: 10000 }
      },
      {
        name: 'від 10 до 15 тис грн',
        range: { from: 10000, to: 15000 }
      },
      {
        name: 'від 15 до 22 тис грн',
        range: { from: 15000, to: 22000 }
      },
      {
        name: 'від 22 до 27 тис грн',
        range: { from: 22000, to: 27000 }
      },
      {
        name: 'від 40 тис грн',
        range: { from: 40000, to: '' }
      }
    ]
  },
  house: {
    rent: [
      {
        name: 'до 10 тис грн',
        range: { from: 0, to: 10000 }
      },
      {
        name: 'від 10 до 20 тис грн',
        range: { from: 10000, to: 20000 }
      },
      {
        name: 'від 30 до 42,5 тис грн',
        range: { from: 30000, to: 42500 }
      },
      {
        name: 'від 100 тис грн',
        range: { from: 100000, to: '' }
      }
    ],
    sell: [
      {
        name: 'до 40 тис $',
        range: { from: 0, to: 40000 }
      },
      {
        name: 'від 40 до 60 тис $',
        range: { from: 40000, to: 60000 }
      },
      {
        name: 'від 60 до 75 тис $',
        range: { from: 60000, to: 75000 }
      },
      {
        name: 'від 75 до 100 тис $',
        range: { from: 75000, to: 100000 }
      },
      {
        name: 'від 100 тис $',
        range: { from: 100000, to: '' }
      }
    ]
  },
  commerce: {
    rent: [
      {
        name: 'до 10 тис грн',
        range: { from: 0, to: 10000 }
      },
      {
        name: 'від 10 до 20 тис грн',
        range: { from: 10000, to: 20000 }
      },
      {
        name: 'від 30 до 42,5 тис грн',
        range: { from: 30000, to: 42500 }
      },
      {
        name: 'від 100 тис грн',
        range: { from: 100000, to: '' }
      }
    ],
    sell: [
      {
        name: 'до 40 тис $',
        range: { from: 0, to: 40000 }
      },
      {
        name: 'від 40 до 60 тис $',
        range: { from: 40000, to: 60000 }
      },
      {
        name: 'від 60 до 75 тис $',
        range: { from: 60000, to: 75000 }
      },
      {
        name: 'від 75 до 100 тис $',
        range: { from: 75000, to: 100000 }
      },
      {
        name: 'від 100 тис $',
        range: { from: 100000, to: '' }
      }
    ]
  },
  land: {
    rent: [],
    sell: [
      {
        name: 'до 40 тис $',
        range: { from: 0, to: 40000 }
      },
      {
        name: 'від 40 до 60 тис $',
        range: { from: 40000, to: 60000 }
      },
      {
        name: 'від 60 до 75 тис $',
        range: { from: 60000, to: 75000 }
      },
      {
        name: 'від 75 до 100 тис $',
        range: { from: 75000, to: 100000 }
      },
      {
        name: 'від 100 тис $',
        range: { from: 100000, to: '' }
      }
    ]
  },
  parking: {
    rent: [],
    sell: []
  }
};
