<template>
  <filter-item-wrapper
    title="Стан"
    icon="menu_icon5"
    collapse="collapse-condition"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterCondition"
        class="clear-section-filter"
        @click="resetFilterCondition"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="">
        <div class="filter-group-btns mb-12px">
          <ul class="filter-group-btns__list filter-group-btns__list--grid">
            <li v-for="group in groups" :key="group.id">
              <button
                class="w-100"
                :class="{ _active: isActive(group.id) }"
                @click.prevent="selectGroup(group.id)"
              >
                {{ group.name }}
              </button>
            </li>
          </ul>
        </div>

        <multiselect
          v-model="repair"
          placeholder="Стан"
          label="name"
          track-by="label"
          :close-on-select="false"
          :clear-on-select="false"
          :show-no-results="false"
          :show-labels="false"
          :multiple="true"
          :searchable="false"
          :options="choises_label"
          class="_sm"
        >
          <template slot="option" slot-scope="props">
            <div
              class="multiselect-option"
              :class="getOptionClass(props.option.group)"
            >
              {{ props.option.name }}
            </div>
          </template>
        </multiselect>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import LabelsMixin from '@/mixins/LabelsMixin';
import Multiselect from 'vue-multiselect';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'FilterCondition',
  mixins: [LabelsMixin],
  components: {
    FilterItemWrapper,
    Multiselect
  },
  data() {
    return {
      groups: [
        {
          id: 1,
          name: 'Під ремонт'
        },
        {
          id: 2,
          name: 'Жилий стан'
        },
        {
          id: 3,
          name: 'З ремонтом'
        }
      ],
      activeGroups: new Set()
    };
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterCondition: 'existsTurnOnFilterCondition'
    }),
    repair: {
      get() {
        return this.$store.state.filter_condition.form.repair;
      },
      set(data) {
        this.changeCondition(data);
      }
    }
  },
  watch: {
    repair: {
      handler(newValue) {
        if (newValue) {
          this.initializeActiveGroups(newValue);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      changeCondition: 'CHANGE_CONDITION',
      resetFilterCondition: 'RESET_FILTER_CONDITION'
    }),
    getOptionClass(group) {
      if (group === 1) return 'option-group-1';
      if (group === 2) return 'option-group-2';
      return 'option-group-3';
    },
    initializeActiveGroups(repair) {
      this.activeGroups.clear();

      repair.forEach((item) => {
        const group = item.group;
        if (group && !this.activeGroups.has(group)) {
          this.activeGroups.add(group);
        }
      });
    },
    selectGroup(groupId) {
      if (this.activeGroups.has(groupId)) {
        this.activeGroups.delete(groupId);

        const groupOptions = this.choises_label.filter(
          (opt) => opt.group === groupId
        );

        this.repair = this.repair.filter(
          (selected) =>
            !groupOptions.some((opt) => opt.label === selected.label)
        );
      } else {
        this.activeGroups.add(groupId);

        const groupOptions = this.choises_label.filter(
          (opt) => opt.group === groupId
        );

        this.repair = [...new Set([...this.repair, ...groupOptions])];
      }

      if (this.activeGroups.size === this.groups.length) {
        this.repair = [...this.choises_label];
      }
    },
    isActive(groupId) {
      const groupOptions = this.choises_label.filter(
        (option) => option.group === groupId
      );

      return groupOptions.every((option) =>
        this.repair.some((selected) => selected.label === option.label)
      );
    }
  }
};
</script>
