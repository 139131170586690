<template>
  <filter-item-wrapper
    title="Комунікації"
    icon="menu_icon2"
    collapse="collapse-communications"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterCommunication"
        @click="resetFilterCommunication"
        class="clear-section-filter"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="form-row mb-2">
        <div class="col-6">
          <multiselect
            v-model="heating"
            placeholder="Опалення"
            label="name"
            track-by="label"
            :close-on-select="false"
            :clear-on-select="false"
            :show-no-results="false"
            :show-labels="false"
            :options="heating_data"
            :multiple="true"
            class="_sm"
          >
          </multiselect>
        </div>

        <div class="col-6">
          <multiselect
            v-model="water"
            placeholder="Вода"
            label="name"
            track-by="label"
            :close-on-select="false"
            :clear-on-select="false"
            :show-no-results="false"
            :show-labels="false"
            :options="water_data"
            :multiple="true"
            class="_sm"
          >
          </multiselect>
        </div>
      </div>

      <div class="form-row mb-2">
        <div class="col-6">
          <multiselect
            v-model="gas"
            placeholder="Газ"
            label="name"
            track-by="label"
            :close-on-select="false"
            :clear-on-select="false"
            :show-no-results="false"
            :show-labels="false"
            :options="gas_data"
            :multiple="true"
            class="_sm"
          >
          </multiselect>
        </div>

        <div class="col-6">
          <multiselect
            v-model="sewers"
            placeholder="Каналізація"
            label="name"
            track-by="label"
            :close-on-select="false"
            :clear-on-select="false"
            :show-no-results="false"
            :show-labels="false"
            :options="sewers_data"
            :multiple="true"
            class="_sm"
          >
          </multiselect>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import Multiselect from 'vue-multiselect';
import { mapMutations, mapGetters } from 'vuex';
import ComunicationLabelsMixin from './ComunicationLabelsMixin';

export default {
  name: 'FilterCommunications',
  mixins: [ComunicationLabelsMixin],
  components: {
    FilterItemWrapper,
    Multiselect
  },
  methods: {
    ...mapMutations({
      changeWater: 'CHANGE_WATER',
      changeGas: 'CHANGE_GAS',
      changeHeating: 'CHANGE_HEATING',
      changeSewers: 'CHANGE_SEWERS',
      resetFilterCommunication: 'RESET_FILTER_COMMUNICATION'
    })
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterCommunication: 'existsTurnOnFilterCommunication'
    }),
    water: {
      get() {
        return this.$store.state.filter_communication.form.water;
      },
      set(data) {
        this.changeWater(data);
      }
    },
    gas: {
      get() {
        return this.$store.state.filter_communication.form.gas;
      },
      set(data) {
        this.changeGas(data);
      }
    },
    heating: {
      get() {
        return this.$store.state.filter_communication.form.heating;
      },
      set(data) {
        this.changeHeating(data);
      }
    },
    sewers: {
      get() {
        return this.$store.state.filter_communication.form.sewers;
      },
      set(data) {
        this.changeSewers(data);
      }
    }
  }
};
</script>

<style lang="sass">
#clear-icon
  font-size: 16px
  color: white

.clear-section-filter
  padding-top: 10px
  &:hover
    cursor: pointer
</style>
