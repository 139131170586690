<template>
  <filter-item-wrapper
    title="Поверховість"
    icon="menu_icon3"
    collapse="collapse-number-of-storeys"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterNumberOfStoreys"
        class="clear-section-filter"
        @click="resetFilterNumberOfStoreys"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div class="pb-1">
        <div class="row">
          <div v-if="isFloor" class="col-12 mb-3">
            <FilterRangeInput
              title="Поверх"
              :from.sync="floor_from"
              :to.sync="floor_to"
              :min-value="1"
              :max-value="100"
              :not-first-not-last="true"
              @update:filter="onFloorFilterUpdate"
            />
          </div>

          <div class="col-12">
            <FilterRangeInput
              v-if="buildingType === 'house'"
              title="Поверховість"
              :from.sync="floors_from"
              :to.sync="floors_to"
              :min-value="1"
              :max-value="100"
              :ranges="[]"
              @update:filter="onFloorsFilterUpdate"
            />

            <FilterRangeInput
              v-else
              title="Поверховість"
              :from.sync="floors_from"
              :to.sync="floors_to"
              :min-value="1"
              :max-value="100"
              @update:filter="onFloorsFilterUpdate"
            />
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { ESTATE_TYPES } from '@/helpers/constants';
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import FilterRangeInput from '@/elements/FilterRangeInput.vue';

export default {
  name: 'FilterNumberOfStoreys',
  components: {
    FilterItemWrapper,
    FilterRangeInput
  },
  computed: {
    ...mapState({
      buildingType: (state) => state.filter_header.buildingType,
      aptTypes: (state) => state.filter_type.form.apt_type
    }),
    ...mapGetters({
      existsTurnOnFilterNumberOfStoreys: 'existsTurnOnFilterNumberOfStoreys'
    }),
    isFlat() {
      return this.buildingType === ESTATE_TYPES.flat;
    },
    isHouse() {
      return this.buildingType === ESTATE_TYPES.house;
    },
    isCommerce() {
      return this.buildingType === ESTATE_TYPES.commerce;
    },
    isLand() {
      return this.buildingType === ESTATE_TYPES.land;
    },
    isParking() {
      return this.buildingType === ESTATE_TYPES.parking;
    },
    isFloor() {
      if (this.aptTypes.length !== 0 && this.isCommerce) {
        const aptTypes = [
          'restaurant_or_cafe_or_bar',
          'office_room',
          'shop',
          'beauty_object',
          'health_object',
          'sport_object',
          'event_object'
        ];

        return this.aptTypes.some((type) => aptTypes.includes(type.id));
      }

      return !this.isHouse && !this.isLand && !this.isParking;
    },
    floor_from: {
      get() {
        return this.$store.state.filter_number_of_storeys.form.floor_from;
      },
      set(data) {
        this.changeFloorFrom(data.toString());
      }
    },
    floor_to: {
      get() {
        return this.$store.state.filter_number_of_storeys.form.floor_to;
      },
      set(data) {
        this.changeFloorTo(data.toString());
      }
    },
    floors_from: {
      get() {
        return this.$store.state.filter_number_of_storeys.form.floors_from;
      },
      set(data) {
        this.changeFloorsFrom(data.toString());
      }
    },
    floors_to: {
      get() {
        return this.$store.state.filter_number_of_storeys.form.floors_to;
      },
      set(data) {
        this.changeFloorsTo(data.toString());
      }
    }
  },
  methods: {
    ...mapMutations({
      changeFloorFrom: 'CHANGE_FLOOR_FROM',
      changeFloorTo: 'CHANGE_FLOOR_TO',
      changeFloorsFrom: 'CHANGE_FLOORS_FROM',
      changeFloorsTo: 'CHANGE_FLOORS_TO',
      resetFilterNumberOfStoreys: 'RESET_FILTER_NUMBER_OF_STOREYS'
    }),
    onFloorFilterUpdate({ from, to }) {
      this.floor_from = from;
      this.floor_to = to;
    },
    onFloorsFilterUpdate({ from, to }) {
      this.floors_from = from;
      this.floors_to = to;
    }
  }
};
</script>
