<template>
  <div :class="$bem()">
    <e-input
      v-model="selectedLabel"
      v-bind="{ label: 'Тип матеріалу', readonly: true }"
      @click="opened = true"
    />

    <e-menu v-model="opened" v-bind="{ right: true, top: true }">
      <div v-for="(item, i) in options" :key="i">
        <e-radio
          v-model="selected"
          v-bind="{ value: item, label: item.label }"
          style="line-height: 1.2"
          @input="setValue"
          @close="opened = false"
        />
      </div>
    </e-menu>
  </div>
</template>

<script>
import {
  APT_MATERIAL_TYPE_CONSTANTS,
  PARKING_MATERIAL_TYPES,
  ESTATE_TYPES
} from '@/helpers/constants';
import eMenu from '@/elements/e-menu';
import { objectToArray } from '@/utils/object-to-array';
import eRadio from '@/elements/inputs/e-radio';
import eInput from '@/elements/inputs/e-input';
import component from '@/mixins/component';

export default {
  name: 'add-object-type-material',
  components: { eInput, eRadio, eMenu },
  mixins: [component],
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'flat'
    }
  },
  data: () => ({
    selected: {},
    opened: false
  }),
  computed: {
    isParking() {
      return this.type === ESTATE_TYPES.parking;
    },
    options() {
      if (this.type && this.isParking) {
        return objectToArray(PARKING_MATERIAL_TYPES);
      }

      return objectToArray(APT_MATERIAL_TYPE_CONSTANTS);
    },
    selectedLabel() {
      if (this.type && this.isParking) {
        return this.value ? PARKING_MATERIAL_TYPES[this.value] : '';
      }

      return this.value ? APT_MATERIAL_TYPE_CONSTANTS[this.value] : '';
    }
  },
  methods: {
    setValue({ value }) {
      this.$emit('input', value);
    }
  }
};
</script>
