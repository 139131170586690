<template>
  <filter-item-wrapper
    title="Вартість"
    icon="menu_icon6"
    collapse="collapse-price"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterPrice"
        class="clear-section-filter"
        aria-label="Очистити фільтр"
        @click.prevent="clear"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <div>
        <div
          v-if="suggestedTags && suggestedTags.length"
          class="filter-group-btns mb-12px"
        >
          <ul class="filter-group-btns__list filter-group-btns__list--grid">
            <li v-for="(tag, tagIndex) in suggestedTags" :key="tagIndex">
              <button
                :class="{ _active: isActiveTag(tag) }"
                @click.prevent="selectTag(tag)"
              >
                {{ tag.name }}
              </button>
            </li>
          </ul>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label">
                Від
                <span v-if="businessType === 'rent'"> грн </span>
                <span v-else> $ </span>
              </label>

              <input type="number" v-model="price_from" class="form-input" />
            </div>
          </div>

          <div class="col-6">
            <div class="form-group form-group-filter">
              <label class="filter-modal-label">
                До
                <span v-if="businessType === 'rent'"> грн </span>
                <span v-else> $ </span>
              </label>

              <input
                type="number"
                v-model="price_to"
                name="states[]"
                class="form-input"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </filter-item-wrapper>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import { priceSuggestedTags } from '@/helpers/price-suggested-tags';
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';

export default {
  name: 'FilterPrice',
  components: {
    FilterItemWrapper
  },
  computed: {
    ...mapState({
      businessType: (state) => state.filter_header.businessType,
      buildingType: (state) => state.filter_header.buildingType
    }),
    ...mapGetters({
      existsTurnOnFilterPrice: 'existsTurnOnFilterPrice'
    }),
    price_from: {
      get() {
        return this.$store.state.filter_price.form.price_from;
      },
      set(data) {
        this.changePriceFrom(data);
      }
    },
    price_to: {
      get() {
        return this.$store.state.filter_price.form.price_to;
      },
      set(data) {
        this.changePriceTo(data);
      }
    },
    suggestedTags() {
      return priceSuggestedTags[this.buildingType]?.[this.businessType] || [];
    }
  },
  methods: {
    ...mapMutations({
      changePriceFrom: 'CHANGE_PRICE_FROM',
      changePriceTo: 'CHANGE_PRICE_TO',
      resetFilterPrice: 'RESET_FILTER_PRICE'
    }),
    clear() {
      this.resetFilterPrice();
    },
    selectTag(tag) {
      this.changePriceFrom(tag.range.from);
      this.changePriceTo(tag.range.to);
    },
    isActiveTag(tag) {
      return (
        this.price_from === tag.range.from && this.price_to === tag.range.to
      );
    }
  }
};
</script>
