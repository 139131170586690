<template>
  <div :class="[$bem(), 'wrapper-status-object']">
    <e-checkbox
      v-model="model"
      :label="`Не розміщувати до бази 
      (Об'єкт буде додано до &quot;моїх об'єктів&quot; без розміщення в Базі)`"
    />
  </div>
</template>

<script>
import component from '../../../../../../mixins/component';
import eCheckbox from '../../../../../../elements/inputs/e-checkbox';

export default {
  name: 'status-object',
  components: { eCheckbox },
  mixins: [component],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
