<template>
  <div v-if="showModalSessionExpired">
    <b-modal
      v-model="showModalSessionExpired"
      id="session-expired-popup"
      modal-class="modal-add"
      :static="true"
      aria-labelledby="session-expired-popup-title"
      hide-footer
    >
      <div slot="modal-header">
        <h4 id="session-expired-popup-title" class="text-center">Увага!</h4>

        <button
          class="modal-add__close-btn"
          aria-label="Close Session Expired Modal"
          @click.prevent="$bvModal.hide('session-expired-popup')"
        >
          <img
            src="@/assets/icons/modal-close.svg"
            alt=""
            width="28"
            height="28"
            aria-hidden="true"
          />
        </button>
      </div>

      <div slot="default">
        <div class="modal-add__img">
          <img
            src="@/assets/images/popups/session-expired-popup-01.webp"
            alt=""
            width="208"
            height="208"
          />
        </div>

        <div class="modal-add__subscription-text">
          <p class="mb-0">
            Ваш акаунт було використано для входу з іншого пристрою. Задля
            безпеки, поточна сесія буде завершена. Якщо це були не ви,
            рекомендуємо змінити пароль. Використовувати обліковий запис можна
            лише з одного пристрою одночасно.
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'SessionExpiredPopup',
  computed: {
    showModalSessionExpired: {
      set(val) {
        this.$store.commit('EXPIRE_SESSION', val);
      },
      get() {
        return this.$store.state.profile.sessionExpired;
      }
    }
  }
};
</script>
