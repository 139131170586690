export const ESTATE_TYPES = {
  flat: 'flat',
  house: 'house',
  commerce: 'commerce',
  land: 'land',
  parking: 'parking'
};

export const APT_TYPE_CONSTANTS = {
  apt: 'Квартира',
  room: 'Кімната',
  new_building: 'Новобудова'
};

export const APT_TYPE_CONSTANTS_DOUBLE = {
  apt: 'Вторинний',
  room: 'Кімната',
  new_building: 'Новобудова'
};

const APT_TYPE_CONSTANTS_COTTAGE = {
  house: 'Будинок',
  cottage: 'Дача',
  part_house: 'Частина будинку'
};
export const HOUSE_TYPES = {
  house: 'Будинок',
  part_house: 'Частина будинку',
  cottage: 'Дача',
  unconstructed: 'Недобудова',
  cottage_new_building: 'Котедж',
  new_building: 'Будинок (Новобудова)'
};

export const LAND_TYPES = {
  residential: 'Під жилу забудову',
  commercial: 'Комерційне призначення',
  agricultural: 'Сільськогосподарське призначення'
};

export const PARKING_TYPES = {
  garage: 'Окремий гараж',
  cooperative: 'Місце в гаражному кооперативі',
  parking_place: 'Місце на паркінгу',
  open_parking: 'Місце на стоянці'
};

export const LAND_COMMUNICATIONS = {
  central_water: 'центральний водопровід',
  sewers: 'каналізація',
  septic: 'септик',
  central_sewers: 'центральна каналізація',
  garbage_collection: 'вивіз відходів',
  well: 'свердловина',
  sump: 'криниця',
  gas: 'газ',
  electricity: 'електрика',
  none: 'комунікації відсутні'
};

export const PARKING_COMPLECTATIONS = {
  underground: 'З підвалом',
  view_dent: 'Оглядова яма',
  storage: 'Комора',
  second_floor: 'Другий поверх',
  living: 'Житловий',
  capital: 'Капітальний',
  wc: 'Санвузол',
  bed: 'Спальне місце',
  racks: 'Стелажі',
  charger: 'Зарядка для електромобілів',
  warm: 'З утепленням',
  video: 'Відеоспостереження',
  central_warm: 'Опалення',
  security: 'Охорона',
  alarm: 'Сигналізація',
  asphalt: 'Асфальтна дорога',
  none: 'Нічого з переліченого'
};

export const PARKING_COMMUNICATIONS = {
  central_water: 'Центральний водопровід',
  warming: 'Опалення',
  internet: 'Інтернет',
  central_sewers: 'Центральна каналізація',
  garbage_collection: 'Вивіз відходів',
  gas: 'Газ',
  electricity: 'Електрика',
  none: 'Нічого з переліченого'
};

export const APT_MATERIAL_TYPE_CONSTANTS = {
  brick: 'Цегла',
  panels: 'Панелі',
  blocks: 'Блоки',
  monolit: 'Моноліт',
  foamconcrete: 'Пінобетон',
  aeratedconcrete: 'Газобетон',
  slag: 'Шлак',
  shellrock: 'Ракушняк',
  woods: 'Зруб',
  metall: 'Метал'
};

export const PARKING_MATERIAL_TYPES = {
  brick: 'Цегла',
  panels: 'Панелі',
  blocks: 'Блоки',
  monolit: 'Моноліт',
  foamconcrete: 'Пінобетон',
  aeratedconcrete: 'Газобетон',
  metall: 'Метал'
};

const APT_STATUS_TYPE_CONSTANTS = {
  without_repairs: 'Без ремонту',
  living: 'Жиле',
  with_repair: 'З ремонтом',
  luxury: 'Елітне'
};

export const TYPE_CONSTANTS = {
  flat: 'Квартири',
  house: 'Будинки та Дачі',
  commerce: 'Комерційна Нерухомість',
  land: 'Земельні ділянки',
  parking: 'Гаражі та паркінги'
};

const TYPE_CONSTANTS_OBJECTS = {
  flat_p: 'Квартири',
  house_p: 'Будинки та Дачі',
  commerce: 'Комерційна Нерухомість',
  lands: 'Земельні ділянки',
  parkings: 'Гаражі та паркінги'
};

export const TYPE_CONSTANTS_PLURAL = {
  flat: 'Квартири',
  house: 'Будинки та Дачі',
  commerce: 'Комерція',
  land: 'Земельні ділянки',
  parking: 'Гаражі та паркінги'
};

export const BUSINESS_TYPES = {
  sell: 'Продам',
  rent: 'Здам'
  // rent_per_day: 'Здам по добово'
};

export const LABELS = {
  liquid: 'Ліквідний',
  nonliquid: 'Неліквідний',
  owner: 'Власник',
  fast_sell: 'Терміновий продаж',
  alternate: 'Альтернативна',
  actual: 'Актуально',
  nonactual: 'Не актуально',
  rieltor: 'Рієлтор',
  recall: 'Передзвонити',
  exclusive: 'Ексклюзив'
};

const LABELS_ARRAY = [
  { id: 'liquid', name: 'Ліквідний' },
  { id: 'nonliquid', name: 'Неліквідний' },
  { id: 'owner', name: 'Власник' },
  { id: 'fast_sell', name: 'Терміновий продаж' },
  { id: 'alternate', name: 'Альтернативна' },
  { id: 'actual', name: 'Актуально' },
  { id: 'nonactual', name: 'Не актуально' },
  { id: 'rieltor', name: 'Рієлтор' },
  { id: 'recall', name: 'Передзвонити' },
  { id: 'exclusive', name: 'Ексклюзив' }
];

const SOURCE = {
  rieltor: 'Ріелтор',
  owner: 'Власник'
};
export const BLACKLIST_TYPES = {
  buyer: 'Покупатель',
  seller: 'Продавец'
};
export const CHOISES_LABEL_OBJECT = {
  without_repair: 'Без ремонту',
  without_internal_works: 'Без внутрішніх робіт',
  without_finishing_works: 'Без оздоблювальних робіт',
  soviet_renovation: 'Радянський ремонт',
  redecorating: 'Косметичний ремонт',
  partial_renovation: 'Частковий ремонт',
  overhaul: 'Капітальний ремонт',
  euro_renovation: 'Євроремонт',
  author_design: 'Авторський дизайн'
};
export const CHOISES_LABEL = [
  {
    label: 'without_repair',
    name: 'Без ремонту',
    group: 1
  },
  {
    label: 'without_internal_works',
    name: 'Без внутрішніх робіт',
    group: 1
  },
  {
    label: 'without_finishing_works',
    name: 'Без оздоблювальних робіт',
    group: 1
  },
  {
    label: 'soviet_renovation',
    name: 'Радянський ремонт',
    group: 2
  },
  {
    label: 'redecorating',
    name: 'Косметичний ремонт',
    group: 2
  },
  {
    label: 'partial_renovation',
    name: 'Частковий ремонт',
    group: 2
  },
  {
    label: 'overhaul',
    name: 'Капітальний ремонт',
    group: 3
  },
  {
    label: 'euro_renovation',
    name: 'Євроремонт',
    group: 3
  },
  {
    label: 'author_design',
    name: 'Авторський дизайн',
    group: 3
  }
];
export const MATERIAL_ARRAY = [
  {
    id: 'brick',
    name: 'Цегла'
  },
  {
    id: 'panels',
    name: 'Панелі'
  },
  {
    id: 'blocks',
    name: 'Блоки'
  },
  {
    id: 'monolit',
    name: 'Моноліт'
  },
  {
    id: 'foamconcrete',
    name: 'Пінобетон'
  },
  {
    id: 'aeratedconcrete',
    name: 'Газобетон'
  },
  {
    id: 'slag',
    name: 'Шлак'
  },
  {
    id: 'shellrock',
    name: 'Ракушняк'
  },
  {
    id: 'woods',
    name: 'Зруб'
  },
  {
    id: 'metall',
    name: 'Метал'
  }
];

const WATER = {
  central: 'центральна',
  central_only_cold: 'центральна (тільки холодна)',
  well: 'свердловина',
  sump: 'колодязь',
  on_street: 'по вулиці',
  column_in_yard: 'колонка у дворі',
  not: 'немає'
};

const GAS = {
  there_is: 'так',
  gasification_possibility: 'можливість газифікувати',
  not: 'немає'
};

const SEWERS = {
  central: 'центральна',
  local: 'місцева',
  not: 'немає'
};

const HEATING = {
  central: 'центральне',
  autonomous: 'автономне',
  gas: 'газове',
  electric: 'електричне',
  convector: 'конвектор',
  solid_fuel_boiler: 'твердопаливний котел',
  oil_boiler: 'рідкопаливний котел',
  fireplace_or_stove: 'камін або пічне',
  not_heating: 'без опалення'
};

export const OBJECT_TYPES = {
  restaurant_or_cafe_or_bar: 'ресторан, кафе, бар',
  office_room: 'офісні приміщення',
  warehouse_or_hangar: 'склад, ангар',
  detached_building: 'окремі будівлі',
  part_of_the_building: 'частина будівлі',
  recreation_center_or_hotel: 'база відпочинку, готель',
  industrial_premise: 'приміщення промислового призначення',
  free_appointment_premise: 'приміщення вільного призначення',
  MAF: 'МАФ (мала архітектурна форма)',
  autoservice: "об'єкт автосервісу",
  farm: 'фермерське господарство',
  shop: 'магазин',
  trading_area: 'торгівельна площа',
  beauty_object: "об'єкт краси",
  health_object: "об'єкт здоров'я",
  sport_object: "об'єкт спорту",
  event_object: "об'єкт заходів",
  production_object: "об'єкт виробництва"
};

export const SHORT_OBJECT_TYPES = {
  industrial_premise: 'ППП',
  free_appointment_premise: 'ПВП',
  MAF: 'МАФ'
};

export const ADDITIONS = {
  RED_LINE: 'Червона лінія',
  PASSAGE_PLACE: 'Прохідне місце',
  NON_RESIDENTIAL_FUND: 'Нежитловий фонд',
  DETACHED_BUILDING: 'Окремо стояча будівля',
  RAILWAY_LINE: 'Залізнична гілка',
  RAMP: 'Рампа',
  FOREST: 'Лiс',
  RIVER_OR_LAKE: 'Річка/Озеро',
  MOUNTAINS: 'Гори',
  SEA: 'Море',

  CARWASH: 'Автомойка',
  TIRE_SERVICE: 'Шиномонтаж',
  CTO: 'СТО',
  LIFT: 'Подьемник',
  PIT: 'Яма',
  FRIDGE: 'Холодильник',
  GENERATOR: 'Генератор',
  BIO_TOILET: 'Биотуалет'
};

export const TEXT_LABELS = {
  photoUsageForAds: 'Можна використовувати фото для реклами',
  userAddedWithoutModeration: 'Додано користувачем без перевірки модераторами',
  objectAddedWithoutModeration: "Об'єкт додано без модерації",
  objectForPersonalUse: "Об'єкт додано для особистого користування",
  cashlessPaymentInfo:
    'Можлива безготівкова оплата, яка може бути використана для угод іпотеки, програми "єОселя", угоди за 280 постановою та інші операції.',
  sellerPaysCommission: 'Продавець оплачує комісію',
  childrenAllowed: 'Можна з дітьми',
  foreignersAllowed: 'Беруть іноземців',
  petsAllowed: 'Можна з тваринами',
  vehiclePlacesLabel: 'Паркомісць',
  requiredField: "Поле обов'язкове для заповнення",
  archiveNotification:
    "Якщо знаєте, що квартира більше не актуальна, натисніть червону кнопку, і ми перенесемо її в архів. За цю інформацію ви отримаєте 2 EasyCoins. <br/> Увага! При подачі заявки на перевірку власник об'єкта дізнається, хто ініціював перевірку."
};

export default Object.freeze({
  estate_types: ESTATE_TYPES,
  types: TYPE_CONSTANTS,
  objects_types: TYPE_CONSTANTS_OBJECTS,
  material_array: MATERIAL_ARRAY,
  apt_types: APT_TYPE_CONSTANTS,
  business_types: BUSINESS_TYPES,
  user_labels: LABELS,
  user_labels_array: LABELS_ARRAY,
  material_labels: APT_MATERIAL_TYPE_CONSTANTS,
  status_labels: APT_STATUS_TYPE_CONSTANTS,
  source_labels: SOURCE,
  objects_types_double: APT_TYPE_CONSTANTS_DOUBLE,
  blacklist_type_labels: BLACKLIST_TYPES,
  types_cottage: APT_TYPE_CONSTANTS_COTTAGE,
  choises_label: CHOISES_LABEL,
  type_constant_plural: TYPE_CONSTANTS_PLURAL,
  house_type: HOUSE_TYPES,
  gas_object: GAS,
  water_object: WATER,
  sewers_object: SEWERS,
  heating_object: HEATING,
  object_types: OBJECT_TYPES,
  short_object_types: SHORT_OBJECT_TYPES,
  additions: ADDITIONS,
  land_types: LAND_TYPES,
  land_communications: LAND_COMMUNICATIONS,
  parking_types: PARKING_TYPES,
  parking_material_types: PARKING_MATERIAL_TYPES,
  parking_complectations: PARKING_COMPLECTATIONS,
  parking_communications: PARKING_COMMUNICATIONS
});

export const INSTAGRAM_LINK = 'https://instagram.com/easybase.dnepr';
export const FACEBOOK_LINK = 'https://www.facebook.com/EasyBase.Dnepr';
export const VIBER_LINK =
  'https://invite.viber.com/?g2=AQAK9IpHpaTlGU%2BoaVtqM8PR7n2OiVrVC69g1LLx7sbtzYdN8w9hYEdqUo7%2BR%2BNd';
export const TELEGRAM_LINK = 'https://t.me/m/a21aggh1MDMy';

export const LANDING_HEADER_HEIGHT = 82;

export const NOTIFICATIONS = {
  viewedAllObjects: "Ви продивились всі об'єкти в базі"
};

export const IS_MOBILE = window.innerWidth <= 991;
