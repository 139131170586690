<template>
  <div class="header-filter pt-3 pb-1">
    <div class="search-block-id">
      <div class="search-block-id__input">
        <input
          type="number"
          v-model="searchId"
          placeholder="Введіть id об'єкту"
          @keydown.enter="searchById"
        />
      </div>

      <button class="search-block-id__btn btn-filter" @click="searchById">
        Пошук
      </button>
    </div>

    <div class="filter-main-title d-flex align-items-center">
      <div class="base-text">БАЗА</div>

      <div class="ml-2 min-w-0">
        <b-dropdown class="dropdown-apartments" :text="buildingTypeLabel">
          <b-dropdown-item
            v-for="(type, typeKey) in types"
            :key="typeKey"
            id="dropdown-menu"
            @click="updateBuildingType(typeKey)"
          >
            <div class="d-flex align-items-center">
              <span class="mr-1">{{ type }}</span>

              <span v-if="typeKey === 'parking'" class="new-label">нове</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="ml-2">
        <b-dropdown class="dropdown-apartments" :text="businessTypeLabel">
          <b-dropdown-item
            v-for="(type, typeIndex) in businessTypeOptions"
            :key="typeIndex"
            id="dropdown-menu"
            @click="updateBusinessType(type.value)"
          >
            {{ type.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BUSINESS_TYPES, TYPE_CONSTANTS_PLURAL } from '@/helpers/constants';
import { objectToArray } from '@/utils/object-to-array';
import LabelsMixin from '@/mixins/LabelsMixin';

export default {
  name: 'FilterHeader',
  mixins: [LabelsMixin],
  computed: {
    ...mapState({
      buildingType: (state) => state.filter_header.buildingType,
      businessType: (state) => state.filter_header.businessType
    }),
    searchId: {
      get() {
        return this.$store.state.filter_header.searchId;
      },
      set(val) {
        this.$store.commit('SET_SEARCH_ID', val);
      }
    },
    businessTypeOptions() {
      return objectToArray(BUSINESS_TYPES) || [];
    },
    businessTypeLabel() {
      return BUSINESS_TYPES[this.businessType] || 'Не вказано';
    },
    buildingTypeLabel() {
      return TYPE_CONSTANTS_PLURAL[this.buildingType] || 'Не вказано';
    }
  },
  methods: {
    updateBuildingType(type) {
      if (type === this.buildingType) return;

      this.$store.commit('SET_BUILDING_TYPE', type);
      this.$store.commit('SET_SEARCH_ID', '');
      this.updateFilter();
    },
    updateBusinessType(type) {
      if (type === this.businessType) return;

      this.$store.commit('SET_BUSINESS_TYPE', type);
      this.$store.commit('SET_SEARCH_ID', '');
      this.updateFilter();
    },
    updateFilter(isSearchById = false) {
      if (!isSearchById) {
        this.$store.dispatch('resetFilters');
      }

      this.$emit('forceRerender');
      this.$store.commit('SET_PAGE', 1);
      this.$store.dispatch('getObjects', { isSearchById });
    },
    searchById() {
      if (!this.$store.state.filter_header.searchId) {
        this.$notify({
          group: 'app',
          type: 'info',
          text: 'id не може бути пустим'
        });

        return;
      }

      this.updateFilter(true);
    }
  }
};
</script>
