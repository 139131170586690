import api from '@/api';
import Vue from 'vue';
import router from '../../router';
import { initWebSocket } from '@/services/websocketService';

export const state = {
  status: '',
  token: localStorage.getItem('accessToken') || '',
  phone: '',
  statusCode: false,
  statusPhone: false,
  errorsAuth: [],

  version: '', // unused
  userRegister: null // unused
};

export const getters = {
  authStatus: (state) => state.status,
  isLoggedIn: (state) => !!state.token,
  loaderObject: (state) => state.loaderObject,
  statusCode: (state) => state.statusCode,
  statusPhone: (state) => state.statusPhone
};

export const mutations = {
  SET_AUTH_STATUS(state, payload) {
    state.status = payload;
  },
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_PHONE(state, payload) {
    state.phone = payload;
  },
  STATUS_REGISTER(state, payload) {
    if (payload.phone) {
      state.statusPhone = payload;
      state.statusCode = payload.code ? payload.code : false;
    } else {
      state.statusCode = payload;
    }
  },
  ERRORS_AUTH(state, payload) {
    state.errorsAuth = payload;
  },
  auth_success(state, { token, user }) {
    state.token = token;
    state.user = user;
  },
  RESET_ERRORS(state) {
    state.errorsAuth = [];
  },
  RESET_REGISTER(state) {
    state.statusPhone = false;
    state.statusCode = false;
  },
  USER_REGISTER(state, payload) {
    state.showVideo = true;
    state.userRegister = payload;
  },
  logout(state) {
    state.status = '';
    state.token = '';
    state.user = '';
  }
};

export const actions = {
  async login({ commit }, credentials) {
    commit('SET_AUTH_STATUS', 'loading');

    try {
      const response = await api.login(credentials);

      if (response.status === 200) {
        const { token, session } = response.data;
        const phone = response.data.user;

        localStorage.setItem('accessToken', token);
        localStorage.setItem('phone', phone);

        commit('SET_TOKEN', token);
        commit('SET_PHONE', phone);
        commit('SET_AUTH_STATUS', '');

        localStorage.setItem('tokenSessionId', token);

        await initWebSocket();

        return response.data;
      }

      localStorage.removeItem('accessToken');
      commit('SET_AUTH_STATUS', '');
      return response;
    } catch (err) {
      localStorage.removeItem('accessToken');
      commit('SET_AUTH_STATUS', '');

      return err;
    }
  },
  async handleSendPhone({ commit }, payload) {
    commit('RESET_ERRORS');

    try {
      const response = await api[
        payload.path === 'sms' ? 'smsForRegistration' : 'smsRestore'
      ](payload.phone);

      commit('STATUS_REGISTER', { id: response.data.id, phone: true });
    } catch (e) {
      commit('ERRORS_AUTH', e.response.data);
    }
  },
  async handleSendCode({ state, commit }, payload) {
    commit('RESET_ERRORS');

    try {
      const response = await api[
        payload.path === 'sms'
          ? 'smsRestoreVerifyForRegistration'
          : 'smsRestoreVerify'
      ](payload.code, state.statusPhone.id);

      if (response.status === 200) {
        if (response.data.id) {
          commit('STATUS_REGISTER', {
            id: response.data.id,
            phone: true,
            code: true
          });
        } else {
          commit('STATUS_REGISTER', true);
        }
      } else {
        commit('ERRORS_AUTH', response.response.data);
      }
    } catch (e) {
      commit('ERRORS_AUTH', e.response.data);
    }
  },
  async handleResetPassword({ state }, data) {
    try {
      const response = await api.passwordRestore(
        state.statusPhone,
        data.phone,
        data.password
      );

      if (response.status === 201) {
        router.push('/login');
        Vue.prototype.$notify({
          group: 'app',
          type: 'success',
          text: 'Пароль успешно изменен!'
        });
      }
    } catch (e) {
      console.log(e, 'error');
    }
  },
  async handleRegister({ state, commit, rootState }, data) {
    commit('RESET_ERRORS');

    try {
      const response = await api.register(state.statusPhone, data);

      if (response.status === 201) {
        commit('USER_REGISTER', response.data);

        const { token } = response.data;
        const { user } = response.data;

        localStorage.setItem('accessToken', token);
        localStorage.setItem('user', user);

        rootState.profile.showVideo = true;
        commit('auth_success', { token, user });
        commit('auth_status', 'success');
        router.push('/profile');
      } else {
        commit('ERRORS_AUTH', response.response.data);
      }
    } catch (e) {
      commit('ERRORS_AUTH', e.response.data);
    }
  },
  async logout({ commit }) {
    commit('logout');
    localStorage.removeItem('accessToken');
  }
};
