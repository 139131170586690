<template>
  <div class="form-group form-group-filter">
    <e-multiselect
      v-on="$listeners"
      placeholder="Почніть вводити:"
      :close-on-select="false"
      :clear-on-select="false"
      :show-no-results="false"
      label="name"
      track-by="id"
      v-bind="$attrs"
      :show-labels="false"
      :multiple="multiple"
      :options="options"
      @search-change="findResidentialComplex"
    >
      <span slot="noResult">Немає результатів!!!</span>
    </e-multiselect>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import component from '@/mixins/component';
import eMultiselect from '@/elements/e-multiselect';

export default {
  name: 'add-object-residential-complex',
  mixins: [component],
  components: { eMultiselect },
  methods: {
    ...mapActions([
      'handleGetResidentialComplex',
      'handleGetBuildings',
      'handleGetGarageCooperatives'
    ])
  },
  props: {
    isBuilding: {
      type: Boolean,
      default: false
    },
    isGarageCooperative: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.handleGetResidentialComplex();
  },
  computed: {
    ...mapGetters(['residentialComplex', 'buildings', 'garageCooperatives']),
    options() {
      switch (true) {
        case this.isGarageCooperative:
          return this.garageCooperatives;
        case this.isBuilding:
          return this.buildings;
        default:
          return this.residentialComplex;
      }
    }
  }
};
</script>
