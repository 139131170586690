<template>
  <filter-item-wrapper
    title="Комплектація"
    icon="menu_icon2"
    collapse="collapse-parking-complectations"
  >
    <template #clear>
      <span
        v-if="existsTurnOnFilterParkingComplectations"
        class="clear-section-filter"
        @click="resetFilterParkingComplectations"
      >
        <i class="mdi mdi-close-circle" id="clear-icon"></i>
      </span>
    </template>

    <template #content>
      <ul class="mb-0 pl-0 list-unstyled">
        <li
          v-for="(completion, completionIndex) in parkingComplectationsOptions"
          :key="completionIndex"
          class="btn-group-toggle"
        >
          <label class="btn test-1">
            <input
              type="checkbox"
              v-model="complectations"
              :value="completion.value"
            />

            <div class="btn-check-border">
              <icon name="check_icon" class="btn-check-icon" />
            </div>

            <span>&nbsp;&nbsp;{{ completion.label }}</span>
          </label>
        </li>
      </ul>
    </template>
  </filter-item-wrapper>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { PARKING_COMPLECTATIONS } from '@/helpers/constants';
import { objectToArray } from '@/utils/object-to-array';
import FilterItemWrapper from '@/components/app/filters/FilterItemWrapper';
import eCheckbox from '@/elements/inputs/e-checkbox';

export default {
  name: 'FilterParkingComplectations',
  components: {
    FilterItemWrapper,
    eCheckbox
  },
  computed: {
    ...mapGetters({
      existsTurnOnFilterParkingComplectations:
        'existsTurnOnFilterParkingComplectations'
    }),
    complectations: {
      get() {
        return this.$store.state.filter_parking_complectations.form
          .complectations;
      },
      set(data) {
        this.changeFilterParkingComplectations(data);
      }
    },
    parkingComplectationsOptions() {
      return objectToArray(PARKING_COMPLECTATIONS) || [];
    }
  },
  methods: {
    ...mapMutations({
      changeFilterParkingComplectations: 'CHANGE_FILTER_PARKING_COMPLECTATIONS',
      resetFilterParkingComplectations: 'RESET_FILTER_PARKING_COMPLECTATIONS'
    })
  }
};
</script>
