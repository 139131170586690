const getDefaultState = () => {
  return {
    form: {
      complectations: []
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterParkingComplectations(state) {
    return state.form.complectations && state.form.complectations.length;
  }
};

export const mutations = {
  CHANGE_FILTER_PARKING_COMPLECTATIONS(state, payload) {
    state.form.complectations = payload;
  },
  RESET_FILTER_PARKING_COMPLECTATIONS(state) {
    state.form.complectations = [];
  }
};
