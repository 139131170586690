import constants from '@/helpers/constants';

const LabelsMixin = {
  data() {
    return {
      apt_types: constants.apt_types,
      material_array: constants.material_array,
      types: constants.types,
      objects_types: constants.objects_types,
      business_types: constants.business_types,
      user_labels: constants.user_labels,
      user_labels_array: constants.user_labels_array,
      objects_types_double: constants.objects_types_double,
      material_labels: constants.material_labels,
      status_labels: constants.status_labels,
      source_labels: constants.source_labels,
      blacklist_types: constants.blacklist_type_labels,
      types_cottage: constants.types_cottage,
      apt_types_test: constants.apt_types_test,
      choises_label: constants.choises_label,
      type_constant_plural: constants.type_constant_plural,
      business_type: constants.business_type,
      house_type: constants.house_type,
      gas_object: constants.gas_object,
      water_object: constants.water_object,
      sewers_object: constants.sewers_object,
      heating_object: constants.heating_object,
      commerce_types: constants.object_types,
      short_commerce_types: constants.short_object_types,
      additions: constants.additions,
      land_types: constants.land_types,
      land_communications: constants.land_communications,
      parking_types: constants.parking_types,
      parking_material_types: constants.parking_material_types,
      parking_communications: constants.parking_communications,
      parking_complectations: constants.parking_complectations
    };
  }
};

export default LabelsMixin;
