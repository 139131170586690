const getDefaultState = () => {
  return {
    form: {
      communications: []
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterParkingCommunications(state) {
    return state.form.communications && state.form.communications.length;
  }
};

export const mutations = {
  CHANGE_FILTER_PARKING_COMMUNICATIONS(state, payload) {
    state.form.communications = payload;
  },
  RESET_FILTER_PARKING_COMMUNICATIONS(state) {
    state.form.communications = [];
  }
};
