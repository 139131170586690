<template>
  <div :class="$bem()">
    <landing-header />

    <landing-auth-screen />

    <landing-info-screen />

    <landing-features />

    <landing-about-screen />

    <landing-reviews />

    <landing-workfor-screen />

    <landing-questions-screen />

    <landing-footer />
  </div>
</template>

<script>
import component from '@/mixins/component';
import LandingAuthScreen from '@/components/auth/landing-auth-screen';
import LandingHeader from '@/components/auth/landing-header';
import LandingInfoScreen from '@/components/auth/landing-info-screen';
import LandingAboutScreen from '@/components/auth/landing-about-screen';
import LandingWorkforScreen from '@/components/auth/landing-workfor-screen';
import LandingQuestionsScreen from '@/components/auth/landing-questions-screen';
import LandingFooter from '@/components/auth/landing-footer';
import LandingFeatures from '@/components/auth/landing-features';
import LandingReviews from '@/components/auth/landing-reviews/';
import { closeWebSocket } from '@/services/websocketService';

export default {
  name: 'Login',
  mixins: [component],
  components: {
    LandingAuthScreen,
    LandingHeader,
    LandingInfoScreen,
    LandingAboutScreen,
    LandingWorkforScreen,
    LandingQuestionsScreen,
    LandingFooter,
    LandingFeatures,
    LandingReviews
  },
  created() {
    if (this.$route.redirectedFrom === '/logout') {
      this.$store.dispatch('logout').catch((err) => console.log(err, 'error'));
      closeWebSocket();
    } else if (localStorage.getItem('accessToken')) {
      this.$router.push('/');
    }
  }
};
</script>

<style lang="sass">
.Login
  margin-top: 0 !important
  margin-bottom: 0 !important
  display: block !important
</style>
