<template>
  <div class="business-type-control">
    <div v-if="error" class="business-type-control__error">
      <span>{{ TEXT_LABELS.requiredField }}</span>
    </div>

    <div
      :class="{
        'business-type-control__border-error': error
      }"
    >
      <div class="business-type-control__frame">
        <span v-if="required" class="business-type-control__required">*</span>

        <div class="business-type-control__items">
          <div
            class="business-type-control__item"
            v-for="(item, i) in options"
            :key="i"
          >
            <e-radio
              v-model="selected"
              v-bind="{ value: item.value, label: item.label }"
              :disabled="disabled"
              class="business-type-control__input"
              :class="[{ _disabled: disabled }]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BUSINESS_TYPES, TEXT_LABELS } from '@/helpers/constants';
import { objectToArray } from '@/utils/object-to-array';
import eRadio from '@/elements/inputs/e-radio';

export default {
  name: 'BusinessTypeControl',
  components: { eRadio },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      TEXT_LABELS
    };
  },
  computed: {
    options() {
      return objectToArray(BUSINESS_TYPES);
    },
    selected: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    }
  },
  watch: {
    error: {
      handler() {
        console.log('watch error: ', this.error);
      },
      deep: true
    }
  }
};
</script>
