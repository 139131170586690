<template>
  <div class="labelled-count input-wrapper" :class="{ error: error }">
    <div v-if="error" class="input-wrapper__errors">
      <span>{{ errorMessage }}</span>
    </div>

    <div class="control-frame">
      <div class="d-flex align-items-center justify-content-between">
        <label for="" class="labelled-count__label">
          {{ label }}

          <sub v-if="required" class="_l-required">*</sub>
        </label>

        <ECounter v-model="localValue" :required="required" :error="error" />
      </div>
    </div>
  </div>
</template>

<script>
import ECounter from '@/elements/e-counter';

export default {
  name: 'LabelledCount',
  components: {
    ECounter
  },
  props: {
    value: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: "Поле обов'язкове для заповнення"
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
};
</script>

<style lang="sass">
.labelled-count
  position: relative
  display: block

  &__label
    display: block
    margin-bottom: 0
</style>
