<template>
  <div class="filter-range-input">
    <label class="filter-range-input__label">{{ title }}</label>

    <ul v-if="ranges && ranges.length" class="filter-range-input__btns">
      <li v-for="(range, index) in ranges" :key="index">
        <button
          :class="{ _active: selectedRange === range.value }"
          :aria-pressed="selectedRange === range.value"
          @click.prevent="selectRange(range.value)"
        >
          {{ range.label }}
        </button>
      </li>

      <template v-if="notFirstNotLast">
        <li>
          <button @click.prevent="selectRange([2, 'not-last'])">
            не перш - не остан.
          </button>
        </li>

        <li>
          <button @click.prevent="selectRange(['', 'not-last'])">
            не останній
          </button>
        </li>
      </template>
    </ul>

    <div class="filter-range-input__row">
      <label class="filter-range-input__col">
        <input
          type="number"
          :value="from"
          :aria-label="`Введіть значення від для ${title}`"
          placeholder="від"
          class="filter-range-input__input"
          @input="$emit('update:from', $event.target.value)"
        />
      </label>

      <label v-if="to !== 'not-last'" class="filter-range-input__col">
        <input
          type="number"
          :value="to"
          :aria-label="`Введіть значення до для ${title}`"
          placeholder="до"
          class="filter-range-input__input"
          @input="$emit('update:to', $event.target.value)"
        />
      </label>

      <label v-else class="filter-range-input__col">
        <input
          type="text"
          :value="'не останній'"
          placeholder="до"
          readonly
          disabled
          class="filter-range-input__input"
        />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterRangeFilter',
  props: {
    title: {
      type: String,
      required: true
    },
    from: [Number, String],
    to: [Number, String],
    minValue: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 5
    },
    threshold: {
      type: Number,
      default: 5
    },
    notFirstNotLast: {
      type: Boolean,
      default: false
    },
    ranges: {
      type: Array,
      default: () => [
        {
          label: 'до 5',
          value: [1, 5]
        },
        {
          label: '6-9',
          value: [6, 9]
        },
        {
          label: '10-16',
          value: [10, 16]
        },
        {
          label: '17-26',
          value: [17, 26]
        },
        {
          label: 'від 26',
          value: [26, '']
        }
      ]
    }
  },
  data() {
    return {
      selectedRange: null
    };
  },
  methods: {
    selectRange(range) {
      this.selectedRange = range;
      const [from, to] = range;
      this.$emit('update:filter', { from, to });
    }
  }
};
</script>

<style lang="sass">
.filter-range-input
  position: relative

  &__label
    display: block
    padding-left: 0.5rem

    &:not(:last-child)
      margin-bottom: 0.375rem

  &__btns
    display: flex
    flex-wrap: wrap
    gap: 0.5rem
    list-style: none
    margin: 0
    padding: 0

    &:not(:last-child)
      margin-bottom: 0.75rem

    > li
      > button
        display: block
        border: none
        border-radius: var(--border-radius-24)
        min-height: 1.5rem
        padding: 0.125rem 0.5rem
        background-color: var(--color-white-smoke)
        font-size: 0.75rem
        font-weight: 600
        line-height: 1.2
        color: var(--color-blue-whale)
        transition: background-color var(--transition-duration-primary), color var(--transition-duration-primary)

        @media (max-width: 992px)
          min-height: 23.5px
          padding: 0.125rem 0.5rem
          font-size: 0.875rem

        &:not(:disabled)
          &:hover
            background-color: var(--color-havasupai-falls)
            color: var(--color-white)

          &:focus
            outline: none
            background-color: var(--color-havasupai-falls)
            color: var(--color-white)

          &:active
            background-color: var(--color-havasupai-falls)
            color: var(--color-white)

        &._active
          background-color: var(--color-havasupai-falls)
          color: var(--color-white)

  &__row
    --col-width: 50%
    --col-gap: 1rem

    display: flex
    margin: 0 calc(var(--col-gap) / 2 * -1)

  &__col
    flex: 0 0 var(--col-width)
    max-width: var(--col-width)
    margin: 0
    padding: 0 calc(var(--col-gap) / 2)

  &__input
    display: block
    border: 1px solid var(--color-blue-whale)
    border-radius: var(--border-radius-16)
    width: 100%
    min-width: 0
    max-width: 100%
    background-color: var(--color-blue-whale)
    min-height: 1.5rem
    padding: 0.125rem 0.25rem 0.125rem 1rem
    font-size: 13px
    font-weight: 200
    line-height: 1.25
    color: var(--color-white)
    transition: border-color var(--transition-duration-primary)

    &:hover
      border-color: var(--color-white)

    &:focus
      outline: none
      border-color: var(--color-white)
</style>
